import React from 'react';
import './ChordTypeControls.css';

interface ChordTypeControlsProps {
  activeType: string;
  pressedKeys: Set<string>;
}

const ChordTypeControls: React.FC<ChordTypeControlsProps> = ({ activeType, pressedKeys }) => {
  const chordTypes = [
    { id: '7th', key: 'q', label: '7th' },
    { id: '9th', key: 'a', label: '9th' },
    { id: '13th', key: 'z', label: '13th' },
  ];

  return (
    <div className="chord-type-controls">
      {chordTypes.map(type => (
        <div 
          key={type.id}
          className={`typecontrol-button ${activeType === type.id ? 'active' : ''}`}
        >
          <div className='numeral'>{type.label} </div>
          <div className='key-letter'>{type.key.toUpperCase()}</div>
        </div>
      ))}
    </div>
  );
};

export default ChordTypeControls; 