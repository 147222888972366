import React, { useState, useEffect } from 'react';
import './OctaveControls.css';

interface OctaveControlsProps {
  octave: number;
  onChange: (octave: number) => void;
}

const OctaveControls: React.FC<OctaveControlsProps> = ({ octave, onChange }) => {
  // Add state to track button press
  const [upButtonPressed, setUpButtonPressed] = useState(false);
  const [downButtonPressed, setDownButtonPressed] = useState(false);
  
  // Define the min and max octave limits based on available samples
  const MIN_OCTAVE = 1; // C1
  const MAX_OCTAVE = 6; // B6
  
  // Add back just the key event listeners for visual feedback, but don't change the octave
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Only handle arrow keys for visual feedback
      if (e.key === 'ArrowUp') {
        setUpButtonPressed(true);
        // Don't call onChange here to avoid duplicate octave changes
      } else if (e.key === 'ArrowDown') {
        setDownButtonPressed(true);
        // Don't call onChange here to avoid duplicate octave changes
      }
    };
    
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        setUpButtonPressed(false);
      } else if (e.key === 'ArrowDown') {
        setDownButtonPressed(false);
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  
  return (
    <div className="octave-controls">
      <button 
        className={`octave-button ${upButtonPressed ? 'active' : ''}`}
        onMouseDown={() => setUpButtonPressed(true)}
        onMouseUp={() => setUpButtonPressed(false)}
        onMouseLeave={() => setUpButtonPressed(false)}
        onClick={() => {
          if (octave < MAX_OCTAVE) {
            onChange(octave + 1);
          }
        }}
      >
        <span className="numeral">↑</span>
      </button>
      
      <button 
        className={`octave-button ${downButtonPressed ? 'active' : ''}`}
        onMouseDown={() => setDownButtonPressed(true)}
        onMouseUp={() => setDownButtonPressed(false)}
        onMouseLeave={() => setDownButtonPressed(false)}
        onClick={() => {
          if (octave > MIN_OCTAVE) {
            onChange(octave - 1);
          }
        }}
      >
        <span className="numeral">↓</span>
      </button>
    </div>
  );
};

export default OctaveControls; 