import React from 'react';
import OctaveControls from './OctaveControls';
import LoopButton from './LoopButton';
import './ControlsRow.css';

interface ControlsRowProps {
  octave: number;
  isModalActive: boolean;
  onOctaveChange: (octave: number) => void;
  onModalToggle?: () => void;
  genre: string;
  isLoopPlaying: boolean;
  loopProgress: number;
  loopDuration: number;
  onToggleLoop: () => void;
  loopAudioReady: boolean;
  loopInitialized: boolean;
}

const ControlsRow: React.FC<ControlsRowProps> = ({
  octave,
  isModalActive,
  onOctaveChange,
  onModalToggle,
  genre,
  isLoopPlaying,
  loopProgress,
  loopDuration,
  onToggleLoop,
  loopAudioReady,
  loopInitialized
}) => {
  return (
    <div className="controls-row">
      <div className="controls-left">
      {onModalToggle && (
          <button 
            className={`mood-button ${isModalActive ? 'active' : ''}`}
            onClick={onModalToggle}
          >
            <div className="numeral">mood shift</div>
            <div className="key-letter">SPACE</div>
          </button>
        )}
        
      </div>
      
      <div className="controls-right">
        
        
        <LoopButton 
          genre={genre}
          isPlaying={isLoopPlaying}
          progress={loopProgress}
          duration={loopDuration}
          audioReady={loopAudioReady}
          initialized={loopInitialized}
          onTogglePlay={onToggleLoop}
        />

        <OctaveControls 
          octave={octave} 
          onChange={onOctaveChange} 
        />

      </div>
    </div>
  );
};

export default ControlsRow; 