import React, { useEffect, useState, useRef } from 'react';
import './LoopButton.css';

interface LoopButtonProps {
  genre: string;
  isPlaying: boolean;
  onTogglePlay: () => void;
  progress: number; // 0 to 1 representing loop progress
  duration: number; // Duration of the loop in seconds
  audioReady?: boolean;
  initialized?: boolean;
}

const LoopButton: React.FC<LoopButtonProps> = ({ 
  genre, 
  isPlaying, 
  onTogglePlay,
  progress,
  duration,
  audioReady = true,
  initialized = true
}) => {
  // Use a ref to track the animation
  const requestRef = useRef<number>();
  const startTimeRef = useRef<number>();
  const [progressValue, setProgressValue] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  
  // Only show loading indicator after a delay
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    
    if (initialized && !audioReady) {
      // Only show loading after a short delay to avoid flashing
      timeoutId = setTimeout(() => {
        setShowLoading(true);
      }, 500);
    } else {
      setShowLoading(false);
    }
    
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [initialized, audioReady]);
  
  // Set up animation loop when playing state changes
  useEffect(() => {
    if (isPlaying && duration > 0) {
      // Start animation
      startTimeRef.current = performance.now();
      
      const animate = (time: number) => {
        if (!startTimeRef.current) startTimeRef.current = time;
        const elapsed = time - startTimeRef.current;
        
        // Calculate progress based on loop duration (0-1)
        // duration is in seconds, elapsed is in milliseconds
        const durationMs = duration * 1000;
        const normalizedElapsed = elapsed % durationMs;
        const newProgress = normalizedElapsed / durationMs;
        
        setProgressValue(newProgress);
        
        // Continue animation loop
        requestRef.current = requestAnimationFrame(animate);
      };
      
      requestRef.current = requestAnimationFrame(animate);
    } else {
      // Stop animation
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      setProgressValue(0);
    }
    
    // Cleanup
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isPlaying, duration]);
  
  // Convert progress to percentage for the gradient
  const progressPercent = Math.floor(progressValue * 100);
  
  // Handle click with explicit event handler
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (initialized) {
      console.log('LoopButton clicked, toggling playback');
      onTogglePlay();
    }
  };
  
  return (
    <div 
      className={`loop-button ${isPlaying ? 'active' : ''} ${showLoading ? 'loading' : ''} ${!initialized ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {/* Show disabled state if not initialized */}
      {!initialized && (
        <div className="disabled-overlay">
          <span>Audio disabled</span>
        </div>
      )}
      
      {/* Show loading indicator if initialized but not ready */}
      {showLoading && (
        <div className="loading-indicator"></div>
      )}
      
      {/* Progress indicator using conic-gradient */}
      {isPlaying && (
        <div 
          className="progress-indicator"
          style={{
            background: `conic-gradient(
              #FE3500 ${progressPercent}%, 
              transparent ${progressPercent}%
            )`
          }}
        />
      )}
      
      <div className="loop-button-inner">
        <div className="numeral">
          <svg width="20" height="20" viewBox="0 0 20 20" fill='#FFF' xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_180_1597)">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.13617 1.1363C1.42907 0.843403 1.90394 0.843403 2.19683 1.1363L4.58345 3.52292C6.10248 2.92651 7.98391 2.58337 9.99984 2.58337C12.0158 2.58337 13.8972 2.92651 15.4162 3.52292L17.8028 1.1363C18.0957 0.843403 18.5706 0.843403 18.8635 1.1363C19.1564 1.42919 19.1564 1.90406 18.8635 2.19696L16.8393 4.22119C18.1289 5.01007 19.0832 6.12248 19.0832 7.50004C19.0832 9.08776 17.8156 10.3233 16.2278 11.1171C14.5952 11.9335 12.3922 12.4167 9.99984 12.4167C7.60746 12.4167 5.4045 11.9335 3.77187 11.1171C2.18411 10.3233 0.916504 9.08776 0.916504 7.50004C0.916504 6.12248 1.87074 5.01007 3.16041 4.22119L1.13617 2.19696C0.843281 1.90406 0.843281 1.42919 1.13617 1.1363ZM4.25887 5.31965C2.9629 6.01312 2.4165 6.81798 2.4165 7.50004C2.4165 8.21351 3.01438 9.06134 4.44269 9.7755C5.82614 10.4672 7.78984 10.9167 9.99984 10.9167C12.2098 10.9167 14.1735 10.4672 15.557 9.7755C16.9853 9.06134 17.5832 8.21351 17.5832 7.50004C17.5832 6.81798 17.0368 6.01312 15.7408 5.31965L12.1968 8.86362C11.9039 9.15652 11.4291 9.15652 11.1362 8.86362C10.8433 8.57073 10.8433 8.09586 11.1362 7.80296L14.2417 4.69739C13.0352 4.31332 11.5805 4.08337 9.99984 4.08337C8.41913 4.08337 6.96443 4.31332 5.75793 4.69739L8.8635 7.80296C9.15639 8.09586 9.15639 8.57073 8.8635 8.86362C8.57061 9.15652 8.09573 9.15652 7.80284 8.86362L4.25887 5.31965Z" />
              <path fillRule="evenodd" clipRule="evenodd" d="M2.4165 7.5C2.4165 7.08579 2.08072 6.75 1.6665 6.75C1.25229 6.75 0.916504 7.08579 0.916504 7.5V14.1667C0.916504 15.6997 2.11613 16.9559 3.77187 17.7838C5.4627 18.6292 7.70328 19.0833 9.99984 19.0833C12.2964 19.0833 14.537 18.6292 16.2278 17.7838C17.8835 16.9559 19.0832 15.6997 19.0832 14.1667V7.5C19.0832 7.08579 18.7474 6.75 18.3332 6.75C17.919 6.75 17.5832 7.08579 17.5832 7.5V14.1667C17.5832 14.8438 17.0268 15.7072 15.557 16.4421C15.3543 16.5435 15.1404 16.6389 14.9165 16.7282V11.1666C14.9165 10.7524 14.5807 10.4166 14.1665 10.4166C13.7523 10.4166 13.4165 10.7524 13.4165 11.1666V17.1972C12.5899 17.3945 11.6878 17.5213 10.75 17.5656V11.6666C10.75 11.2524 10.4142 10.9166 10 10.9166C9.58579 10.9166 9.25 11.2524 9.25 11.6666V17.5657C8.31217 17.5213 7.41008 17.3946 6.5835 17.1972V11.1666C6.5835 10.7524 6.24771 10.4166 5.8335 10.4166C5.41928 10.4166 5.0835 10.7524 5.0835 11.1666V16.7283C4.85946 16.639 4.64545 16.5435 4.44269 16.4421C2.97283 15.7072 2.4165 14.8438 2.4165 14.1667V7.5Z" />
            </g>
            <defs>
              <clipPath id="clip0_180_1597">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="key-letter">/</div>
      </div>
    </div>
  );
};

export default LoopButton; 