import { useState, useEffect, useCallback, useRef } from 'react';
import { getTransitionType } from '../utils/musicTheory';

export interface KeyboardControlsProps {
  setActiveChordType: (chordType: string) => void;
  setActiveVoicing: (voicing: string) => void;
  setIsModalActive: (isActive: boolean) => void;
  playChord: (scale: string, degree: number, octave: number, chordType: string, voicing: string, isModalActive: boolean, isKeyDown?: boolean) => void;
  handlePlayChord: (scale: string, degree: number, octave: number, chordType: string, voicing: string, isModalActive: boolean, isKeyDown?: boolean) => void;
  handlePlayDimChord: (degree: number, isKeyDown?: boolean) => void;
  handleBorrowedRowPlayChord: (degree: number, isKeyDown?: boolean) => void;
  scale: string;
  octave: number;
  activeChordType: string;
  activeVoicing: string;
  isModalActive: boolean;
  genre: string;
  setOctave: (octave: number) => void;
  onLoopToggle: () => void;
  loopCountdown?: number | null;
}

export const useKeyboardControls = ({
  setActiveChordType,
  setActiveVoicing,
  setIsModalActive,
  playChord,
  handlePlayChord,
  handlePlayDimChord,
  handleBorrowedRowPlayChord,
  scale,
  octave,
  activeChordType,
  activeVoicing,
  isModalActive,
  genre = 'pop',
  setOctave,
  onLoopToggle,
  loopCountdown
}: KeyboardControlsProps) => {
  const [pressedKeys, setPressedKeys] = useState<Set<string>>(new Set());
  const [previousChordType, setPreviousChordType] = useState<string>('triad');
  const [previousVoicing, setPreviousVoicing] = useState<string>('closed');
  
  // Track which keys are currently modifying the chord type and voicing
  const activeModifierKeys = useRef<{
    chordType: string | null,
    voicing: string | null
  }>({
    chordType: null,
    voicing: null
  });
  
  // Define all key mappings according to the specified list
  
  // Chord Type Controls
  const CHORD_TYPE_KEYS = {
    'q': '7th',
    'a': '9th',
    'z': '13th'
  };
  
  // Voicing Controls
  const VOICING_KEYS = {
    'w': 'open',
    'e': 'dynamic',
    's': 'drop2',
    'd': 'drop3',
    '㈯': 'spread',
    'x': 'shell',
    'c': 'cluster'
  };
  
  // Transition Row
  // TEMPORARILY DISABLED: Using non-existent Unicode characters to disable key bindings
  // Original keys were: ['1', '2', '3', '4', '5', '6', '7']
  // To restore, simply replace these with the original keys
  const TRANSITION_KEYS = ['㈠', '㈡', '㈢', '㈣', '㈤', '㈥', '㈦'];
  
  // Progression Row
  const PROGRESSION_KEYS = ['f', 'g', 'h', 'j', 'k', 'l', ';'];
  
  // Borrowed Row
  const BORROWED_KEYS = ['r', 't', 'y', 'u', 'i', 'o', 'p'];
  
  // Loop button
  const LOOP_KEY = '/';
  
  // Modal
  const MODAL_KEY = ' '; // Space
  
  // Octave controls
  const OCTAVE_UP_KEY = 'arrowup';
  const OCTAVE_DOWN_KEY = 'arrowdown';
  
  // Handle key down events
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    // Skip if in an input field
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
      return;
    }

    const key = e.key.toLowerCase();
    
    // Handle loop toggle key
    if (key === LOOP_KEY || key === 'slash') {
      // Skip if countdown is in progress
      if (loopCountdown !== null) {
        e.preventDefault();
        return;
      }
      
      if (onLoopToggle) {
        onLoopToggle();
      }
      e.preventDefault();
      return; // Don't process further
    }
    
    // Special handling for Z key - don't process it here since we handle it in App.tsx
    if (key === 'z') {
      return;
    }
    
    // Check if this is a repeat event from holding down the key
    if (e.repeat) {
      // Prevent default to stop auto-repeat for all keys
      e.preventDefault();
      return;
    }
    
    // Only update if the key is not already pressed - this prevents auto-repeat
    if (!pressedKeys.has(key)) {
      setPressedKeys(prev => {
        const newKeys = new Set(prev);
        newKeys.add(key);
        return newKeys;
      });
      
      // Handle transition keys
      if (TRANSITION_KEYS.includes(key)) {
        const degree = TRANSITION_KEYS.indexOf(key) + 1;
        
        // Use handlePlayDimChord if available, otherwise fall back to playChord
        if (handlePlayDimChord) {
          handlePlayDimChord(degree, true);
        } else {
          const transitionType = getTransitionType(genre || 'pop');
          playChord(scale, degree, octave, transitionType, activeVoicing, isModalActive, true);
        }
      }
      
      // Handle progression keys
      if (PROGRESSION_KEYS.includes(key)) {
        const degree = PROGRESSION_KEYS.indexOf(key) + 1;
        
        // Use handlePlayChord if available, otherwise fall back to playChord
        if (handlePlayChord) {
          handlePlayChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, true);
        } else {
          playChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, true);
        }
      }

      // Handle borrowed keys
      if (BORROWED_KEYS.includes(key)) {
        const degree = BORROWED_KEYS.indexOf(key) + 1;
        
        // Use handleBorrowedRowPlayChord if available, otherwise fall back to handlePlayChord
        if (handleBorrowedRowPlayChord) {
          handleBorrowedRowPlayChord(degree, true);
        } else if (handlePlayChord) {
          handlePlayChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, true);
        } else {
          playChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, true);
        }
      }
      
      // Handle chord type keys
      if (key in CHORD_TYPE_KEYS) {
        // Only store previous chord type if no other chord type key is active
        if (activeModifierKeys.current.chordType === null) {
          setPreviousChordType(activeChordType); // Store current chord type
        }
        
        // Set this as the active chord type key
        activeModifierKeys.current.chordType = key;
        
        // Set the new chord type
        setActiveChordType(CHORD_TYPE_KEYS[key as keyof typeof CHORD_TYPE_KEYS]);
      }
      
      // Handle voicing keys
      if (key in VOICING_KEYS) {
        // Only store previous voicing if no other voicing key is active
        if (activeModifierKeys.current.voicing === null) {
          setPreviousVoicing(activeVoicing); // Store current voicing
        }
        
        // Set this as the active voicing key
        activeModifierKeys.current.voicing = key;
        
        // Set the new voicing
        setActiveVoicing(VOICING_KEYS[key as keyof typeof VOICING_KEYS]);
      }
      
      // Handle modal key (space)
      if (key === MODAL_KEY) {
        setIsModalActive(true);
      }
      
      // Handle octave keys - ensure we increment/decrement by exactly 1
      if (key === OCTAVE_UP_KEY && setOctave) {
        setOctave(octave + 1);
      } else if (key === OCTAVE_DOWN_KEY && setOctave) {
        setOctave(octave - 1);
      }
    }
    
    // Always prevent default for these keys to stop auto-repeat and scrolling
    if (TRANSITION_KEYS.includes(key) || 
        PROGRESSION_KEYS.includes(key) || 
        BORROWED_KEYS.includes(key) ||
        key === MODAL_KEY ||
        key === OCTAVE_UP_KEY ||
        key === OCTAVE_DOWN_KEY) {
      e.preventDefault();
    }
  }, [pressedKeys, setActiveChordType, setActiveVoicing, setIsModalActive, activeChordType, activeVoicing, playChord, handlePlayChord, handlePlayDimChord, handleBorrowedRowPlayChord, scale, octave, isModalActive, genre, setOctave, onLoopToggle, loopCountdown]);
  
  // Handle key up events
  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    const key = e.key.toLowerCase();
    
    // Skip loop key handling on key up
    if (key === LOOP_KEY || key === 'slash') {
      return;
    }
    
    // Special handling for Z key - make sure it's removed from pressed keys
    if (key === 'z') {
      setPressedKeys(prev => {
        const newKeys = new Set(prev);
        newKeys.delete(key);
        return newKeys;
      });
      return;
    }
    
    setPressedKeys(prev => {
      const newKeys = new Set(prev);
      newKeys.delete(key);
      return newKeys;
    });
    
    // Handle transition keys
    if (TRANSITION_KEYS.includes(key)) {
      const degree = TRANSITION_KEYS.indexOf(key) + 1;
      
      // Use handlePlayDimChord if available, otherwise fall back to playChord
      if (handlePlayDimChord) {
        handlePlayDimChord(degree, false);
      } else {
        playChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, false);
      }
      return;
    }
    
    // Handle progression keys
    if (PROGRESSION_KEYS.includes(key)) {
      const degree = PROGRESSION_KEYS.indexOf(key) + 1;
      
      // Use handlePlayChord if available, otherwise fall back to playChord
      if (handlePlayChord) {
        handlePlayChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, false);
      } else {
        playChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, false);
      }
      return;
    }

    // Handle borrowed keys
    if (BORROWED_KEYS.includes(key)) {
      const degree = BORROWED_KEYS.indexOf(key) + 1;
      
      // Use handleBorrowedRowPlayChord if available, otherwise fall back to handlePlayChord
      if (handleBorrowedRowPlayChord) {
        handleBorrowedRowPlayChord(degree, false);
      } else if (handlePlayChord) {
        handlePlayChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, false);
      } else {
        playChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, false);
      }
      return;
    }
    
    // Handle chord type keys
    if (key in CHORD_TYPE_KEYS) {
      // Only restore previous chord type if this is the active chord type key
      if (activeModifierKeys.current.chordType === key) {
        // Clear the active chord type key
        activeModifierKeys.current.chordType = null;
        
        // Check if any other chord type keys are still pressed
        let otherChordTypeKeyPressed = false;
        for (const chordTypeKey of Object.keys(CHORD_TYPE_KEYS)) {
          if (pressedKeys.has(chordTypeKey) && chordTypeKey !== key) {
            // Another chord type key is still pressed, make it the active one
            activeModifierKeys.current.chordType = chordTypeKey;
            setActiveChordType(CHORD_TYPE_KEYS[chordTypeKey as keyof typeof CHORD_TYPE_KEYS]);
            otherChordTypeKeyPressed = true;
            break;
          }
        }
        
        // If no other chord type keys are pressed, restore the previous chord type
        if (!otherChordTypeKeyPressed) {
          setActiveChordType(previousChordType);
        }
      }
    }
    
    // Handle voicing keys
    if (key in VOICING_KEYS) {
      // Only restore previous voicing if this is the active voicing key
      if (activeModifierKeys.current.voicing === key) {
        // Clear the active voicing key
        activeModifierKeys.current.voicing = null;
        
        // Check if any other voicing keys are still pressed
        let otherVoicingKeyPressed = false;
        for (const voicingKey of Object.keys(VOICING_KEYS)) {
          if (pressedKeys.has(voicingKey) && voicingKey !== key) {
            // Another voicing key is still pressed, make it the active one
            activeModifierKeys.current.voicing = voicingKey;
            setActiveVoicing(VOICING_KEYS[voicingKey as keyof typeof VOICING_KEYS]);
            otherVoicingKeyPressed = true;
            break;
          }
        }
        
        // If no other voicing keys are pressed, restore the previous voicing
        if (!otherVoicingKeyPressed) {
          setActiveVoicing(previousVoicing);
        }
      }
    }
    
    // Handle modal key (space)
    if (key === MODAL_KEY) {
      setIsModalActive(false);
    }
  }, [pressedKeys, setIsModalActive, previousChordType, setActiveChordType, previousVoicing, setActiveVoicing, playChord, handlePlayChord, handlePlayDimChord, handleBorrowedRowPlayChord, scale, octave, activeChordType, activeVoicing, isModalActive, genre]);
  
  // Set up event listeners
  useEffect(() => {
    // Add event listeners with capture phase to ensure they run first
    window.addEventListener('keydown', handleKeyDown, true);
    window.addEventListener('keyup', handleKeyUp, true);
    
    // Handle window blur - release all keys when window loses focus
    const handleBlur = () => {
      if (pressedKeys.size > 0) {
        // Release all chord keys
        pressedKeys.forEach(key => {
          // Handle transition keys
          if (TRANSITION_KEYS.includes(key)) {
            const degree = TRANSITION_KEYS.indexOf(key) + 1;
            
            // Use handlePlayDimChord if available, otherwise fall back to playChord
            if (handlePlayDimChord) {
              handlePlayDimChord(degree, false);
            } else {
              const transitionType = getTransitionType(genre || 'pop');
              playChord(scale, degree, octave, transitionType, activeVoicing, isModalActive, false);
            }
          }
          
          // Handle progression keys
          if (PROGRESSION_KEYS.includes(key)) {
            const degree = PROGRESSION_KEYS.indexOf(key) + 1;
            
            // Use handlePlayChord if available, otherwise fall back to playChord
            if (handlePlayChord) {
              handlePlayChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, false);
            } else {
              playChord(scale, degree, octave, activeChordType, activeVoicing, isModalActive, false);
            }
          }
        });
        
        // Clear all pressed keys
        setPressedKeys(new Set());
        
        // Reset active modifier keys
        activeModifierKeys.current = {
          chordType: null,
          voicing: null
        };
        
        // Restore previous chord type and voicing
        setActiveChordType(previousChordType);
        setActiveVoicing(previousVoicing);
        
        // Reset modal state if space was pressed
        if (isModalActive) {
          setIsModalActive(false);
        }
      }
    };
    
    // Add a window blur listener
    window.addEventListener('blur', handleBlur);
    
    // Add a visibility change listener
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        handleBlur();
      }
    });
    
    // Add a mousedown listener to detect clicks outside the window
    document.addEventListener('mousedown', (e) => {
      // If clicking outside the app container, release all keys
      const appContainer = document.querySelector('.app-container');
      if (appContainer && !appContainer.contains(e.target as Node)) {
        handleBlur();
      }
    });
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
      window.removeEventListener('keyup', handleKeyUp, true);
      window.removeEventListener('blur', handleBlur);
      document.removeEventListener('visibilitychange', () => {
        if (document.hidden) {
          handleBlur();
        }
      });
    };
  }, [handleKeyDown, handleKeyUp, pressedKeys, playChord, handlePlayChord, handlePlayDimChord, handleBorrowedRowPlayChord, scale, octave, activeChordType, activeVoicing, isModalActive, genre, setIsModalActive, previousChordType, setActiveChordType, previousVoicing, setActiveVoicing]);
  
  return { pressedKeys };
}; 