import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './NoteDisplay.css';
import { Midi } from '@tonejs/midi';

interface NoteDisplayProps {
  notes: string[];
  chordName: string;
  scaleName: string;
  countdown?: number | null;
  onCountdownComplete?: () => void;
}

// First, create an interface for the chord history item
interface ChordHistoryItem {
  name: string;
  notes: string[];
  timestamp: number; // When the chord was played
  duration?: number; // Optional duration for timing calculation
}

const NoteDisplay: React.FC<NoteDisplayProps> = ({ 
  notes, 
  chordName, 
  scaleName,
  countdown = null,
  onCountdownComplete
}) => {
  // State to track the latest chord and notes
  const [latestChord, setLatestChord] = useState<string>('');
  const [latestNotes, setLatestNotes] = useState<string[]>([]);
  
  // State to track chord history
  const [chordHistory, setChordHistory] = useState<ChordHistoryItem[]>([]);
  
  // Ref to access the notes-graph DOM elements
  const notesGraphRef = useRef<HTMLDivElement>(null);
  
  // Ref to track the last update time for debouncing
  const lastUpdateTimeRef = useRef<number>(0);
  
  // Add state for copy feedback
  const [copySuccess, setCopySuccess] = useState(false);
  
  // Add state for confirmation dialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // Add state for dialog animation
  const [isClosing, setIsClosing] = useState(false);
  
  // Add state for MIDI download feedback
  const [midiSuccess, setMidiSuccess] = useState(false);
  
  // Helper function to convert sharp notes to flat notation
  const convertToFlatNotation = useCallback((notes: string[]): string[] => {
    const sharpToFlat: Record<string, string> = {
      'C#': 'Db', 
      'D#': 'Eb', 
      'F#': 'Gb', 
      'G#': 'Ab', 
      'A#': 'Bb'
    };
    
    return notes.map(note => {
      // Extract note name and octave
      const match = note.match(/([A-G][#b]?)(\d+)/);
      if (match) {
        const [_, noteName, octave] = match;
        // Convert sharp notes to flat notation
        if (noteName.includes('#') && sharpToFlat[noteName]) {
          return `${sharpToFlat[noteName]}${octave}`;
        }
      }
      return note;
    });
  }, []);
  
  // Call the completion callback when countdown finishes
  useEffect(() => {
    if (countdown === null && onCountdownComplete) {
      onCountdownComplete();
    }
  }, [countdown, onCountdownComplete]);
  
  // Combined effect to update both chord and notes at once to reduce renders
  useEffect(() => {
    // Don't update during countdown - BUT ONLY WHEN COUNTDOWN IS ACTIVE
    // This allows normal chord history to work when there's no countdown
    if (countdown !== null) {
      // Make sure that when countdown starts, all chord data is cleared
      if (countdown === 3) {
        setChordHistory([]);
        setLatestChord('');
        setLatestNotes([]);
      }
      return;
    }
    
    // Debounce updates to prevent multiple rapid renders
    const now = Date.now();
    if (now - lastUpdateTimeRef.current < 50) { // 50ms debounce
      return;
    }
    lastUpdateTimeRef.current = now;
    
    // Update latest chord
    if (chordName !== latestChord) {
      setLatestChord(chordName);
    }
    
    // Update chord history for every chord played (including repeats)
    if (chordName && chordName.trim() !== '') {
      setChordHistory(prevHistory => {
        const now = Date.now();
        // Calculate duration for the previous chord if it exists
        const newHistory = prevHistory.length > 0 
          ? [
              { 
                name: chordName, 
                notes: notes,
                timestamp: now
              },
              // Update the previous chord with a duration based on time difference
              {
                ...prevHistory[0],
                duration: now - prevHistory[0].timestamp
              },
              ...prevHistory.slice(1)
            ]
          : [
              { 
                name: chordName, 
                notes: notes,
                timestamp: now
              }
            ];
        // Limit to the latest 12 chords
        return newHistory.slice(0, 16);
      });
    }
    
    // Update latest notes
    if (notes && notes.length > 0) {
      // Process notes only once
      const processedNotes = processNotes(notes, chordName);
      setLatestNotes(processedNotes);
    }
  }, [chordName, notes, latestChord, convertToFlatNotation, countdown]);
  
  // Process notes in a single function to avoid multiple state updates
  const processNotes = useCallback((inputNotes: string[], chord: string): string[] => {
    // Convert any sharp notes to flat notation
    const flatNotes = convertToFlatNotation(inputNotes);
    
    // Check for cases where a major chord has incorrect third
    let correctedNotes = [...flatNotes];
    
    // Map of root notes to their expected major third
    const rootToMajorThird: Record<string, Record<string, string>> = {
      'C': { 'E': 'E', 'Eb': 'E' },
      'D': { 'F': 'F#', 'E': 'F#', 'Gb': 'F#' },
      'E': { 'G': 'G#', 'Gb': 'G#', 'Ab': 'G#' },
      'F': { 'A': 'A', 'Ab': 'A' },
      'G': { 'B': 'B', 'Bb': 'B' },
      'A': { 'C': 'C#', 'Db': 'C#' },
      'B': { 'D': 'D#', 'Eb': 'D#' }
    };
    
    // Extract the root note from the chord name (e.g., "D" from "D (ii)")
    const rootMatch = chord ? chord.match(/^([A-G][b#]?)/) : null;
    
    // Check if this is a borrowed chord (contains ♭ or ♯)
    const isBorrowedChord = chord && (chord.includes('♭') || chord.includes('♯'));
    
    if (rootMatch && !chord.includes('m') && !isBorrowedChord && flatNotes.length >= 3) {
      const rootNote = rootMatch[1];
      const baseRoot = rootNote.charAt(0); // Just the letter part (C, D, E, etc.)
      
      // Find the third note in the chord (typically the second element in the array)
      // We need to analyze all notes to find the third
      for (let i = 0; i < flatNotes.length; i++) {
        const note = flatNotes[i];
        const noteMatch = note.match(/^([A-G][b#]?)(\d)$/);
        
        if (noteMatch) {
          const [_, noteName, octave] = noteMatch;
          const noteBase = noteName.charAt(0); // Just the letter part
          
          // Check if this note could be a third that needs correction
          if (rootToMajorThird[baseRoot] && rootToMajorThird[baseRoot][noteBase]) {
            const expectedThird = rootToMajorThird[baseRoot][noteBase];
            
            // If the note doesn't match the expected major third, correct it
            if (noteName !== expectedThird) {
              // Convert any sharp notes in the expected third to flat notation
              let correctedNote = expectedThird;
              if (correctedNote.includes('#')) {
                const sharpToFlat: Record<string, string> = {
                  'C#': 'C', 
                  'D#': 'D', 
                  'F#': 'F', 
                  'G#': 'G', 
                  'A#': 'A'
                };
                correctedNote = sharpToFlat[correctedNote] || correctedNote;
              }
              
              correctedNotes[i] = `${correctedNote}${octave}`;
            }
          }
        }
      }
    }
    
    // Convert any remaining sharp notes to flat notation
    return convertToFlatNotation(correctedNotes);
  }, [convertToFlatNotation]);
  
  // Effect to highlight active notes in the notes-graph
  useEffect(() => {
    if (!notesGraphRef.current) return;
    
    // First, remove 'active' class from all note elements
    const allNoteElements = notesGraphRef.current.querySelectorAll('.flat-0, .flat-1, .flat-2, .sharp-1');
    allNoteElements.forEach(element => {
      element.classList.remove('active');
    });
    
    // Get the notes to display - either corrected or original
    const displayNotes = latestNotes.length > 0 ? latestNotes : notes;
    
    // Then, add 'active' class to elements that match the current notes
    if (displayNotes && displayNotes.length > 0) {
      displayNotes.forEach(note => {
        // Parse the note to extract the note name and octave
        // Note format could be like "C4", "D#3", "Eb5", etc.
        const noteMatch = note.match(/([A-G][b#]?)(\d)/);
        if (noteMatch) {
          const [_, noteName, octave] = noteMatch;
          
          // For notes with sharps, we need to use the flat equivalent in our DOM
          // since # is not valid in CSS selectors
          if (noteName.includes('#')) {
            // Convert to flat equivalent
            const flatEquivalent = getAlternativeNoteId(noteName, octave);
            if (flatEquivalent) {
              const flatElement = notesGraphRef.current?.querySelector(`#${flatEquivalent}`);
              if (flatElement) {
                flatElement.classList.add('active');
              }
            }
          } else {
            // For regular notes and flat notes, try direct lookup
            const noteElement = notesGraphRef.current?.querySelector(`#${noteName}${octave}`);
            if (noteElement) {
              noteElement.classList.add('active');
            } else {
              // If not found, try alternative notation (e.g., Bb vs A#)
              const alternativeId = getAlternativeNoteId(noteName, octave);
              if (alternativeId) {
                const altElement = notesGraphRef.current?.querySelector(`#${alternativeId}`);
                if (altElement) {
                  altElement.classList.add('active');
                }
              }
            }
          }
        }
      });
    }
  }, [latestNotes, notes]);
  
  // Helper function to get alternative notation for a note
  const getAlternativeNoteId = useCallback((noteName: string, octave: string): string | null => {
    const sharpToFlat: Record<string, string> = {
      'C#': 'Db', 
      'D#': 'Eb', 
      'E#': 'F',  // Added E# -> F conversion
      'F#': 'Gb', 
      'G#': 'Ab', 
      'A#': 'Bb',
      'B#': 'C'   // Added B# -> C conversion
    };
    
    const flatToSharp: Record<string, string> = {
      'Db': 'C#', 
      'Eb': 'D#',
      'Fb': 'E',  // Added Fb -> E conversion 
      'Gb': 'F#', 
      'Ab': 'G#', 
      'Bb': 'A#',
      'Cb': 'B'   // Added Cb -> B conversion
    };
    
    // Always prefer flat notation
    if (noteName in sharpToFlat) {
      return `${sharpToFlat[noteName]}${octave}`;
    }
    
    // Only use sharp notation as a fallback
    if (noteName in flatToSharp) {
      return `${flatToSharp[noteName]}${octave}`;
    }
    
    return null;
  }, []);
  
  // Extract just the chord name without the Roman numerals for display
  const displayChordName = useCallback((chord: string) => chord, []);
  
  // Convert any sharp notes in the displayed notes to flat notation
  const displayNotes = useMemo(() => {
    return latestNotes.map(note => {
      const match = note.match(/([A-G][#b]?)(\d+)/);
      if (match) {
        const [_, noteName, octave] = match;
        if (noteName.includes('#')) {
          const sharpToFlat: Record<string, string> = {
            'C#': 'Db', 
            'D#': 'Eb', 
            'F#': 'Gb', 
            'G#': 'Ab', 
            'A#': 'Bb'
          };
          return `${sharpToFlat[noteName] || noteName}${octave}`;
        }
      }
      return note;
    });
  }, [latestNotes]);
  
  // Memoize the chord history display to prevent unnecessary re-renders
  const chordHistoryDisplay = useMemo(() => {
    if (chordHistory.length === 0) {
      return <span className="no-history">play a chord</span>;
    }
    
    return (
      <div className="chord-history-content">
          <div 
            className={`copy-button ${copySuccess ? 'copied' : ''}`}
            onClick={() => {
              setShowConfirmDialog(true);
            }}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="#D8D8D8" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16634 2.91732C3.83482 2.91732 3.51688 3.04901 3.28246 3.28343C3.04804 3.51785 2.91634 3.8358 2.91634 4.16732V15.834C2.91634 16.1655 3.04804 16.4834 3.28246 16.7179C3.51688 16.9523 3.83482 17.084 4.16634 17.084H15.833C16.1645 17.084 16.4825 16.9523 16.7169 16.7179C16.9513 16.4834 17.083 16.1655 17.083 15.834V10.834C17.083 10.6039 17.2696 10.4173 17.4997 10.4173C17.7298 10.4173 17.9163 10.6039 17.9163 10.834V15.834C17.9163 16.3865 17.6968 16.9164 17.3061 17.3071C16.9154 17.6978 16.3855 17.9173 15.833 17.9173H4.16634C3.61381 17.9173 3.0839 17.6978 2.6932 17.3071C2.3025 16.9164 2.08301 16.3865 2.08301 15.834V4.16732C2.08301 3.61478 2.3025 3.08488 2.6932 2.69418C3.0839 2.30348 3.61381 2.08398 4.16634 2.08398H9.16634C9.39646 2.08398 9.58301 2.27053 9.58301 2.50065C9.58301 2.73077 9.39646 2.91732 9.16634 2.91732H4.16634Z" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8847 2.34116C17.8647 2.29274 17.8351 2.24733 17.7961 2.2078C17.7949 2.20661 17.7937 2.20543 17.7925 2.20426C17.753 2.16519 17.7076 2.13563 17.6592 2.1156C17.61 2.09523 17.5562 2.08398 17.4997 2.08398H12.4997C12.2696 2.08398 12.083 2.27053 12.083 2.50065C12.083 2.73077 12.2696 2.91732 12.4997 2.91732H16.4938L9.70505 9.70602C9.54233 9.86874 9.54233 10.1326 9.70505 10.2953C9.86777 10.458 10.1316 10.458 10.2943 10.2953L17.083 3.50657V7.50065C17.083 7.73077 17.2696 7.91732 17.4997 7.91732C17.7298 7.91732 17.9163 7.73077 17.9163 7.50065V2.5011V2.50065C17.9163 2.49982 17.9163 2.49898 17.9163 2.49815C17.916 2.44257 17.9048 2.38956 17.8847 2.34116Z" />
            </svg>
          </div>
          <div className="chord-history">
          <span className="history-chords">
            {[...chordHistory].map((chord, index, array) => (
              <span key={index} className="history-chord">
                {chord.name.includes('•') ? chord.name.split('•')[1] : chord.name}{index < array.length - 1 ? '' : ''}
              </span>
            ))}
          </span>
        </div>
      </div>
    );
  }, [chordHistory, copySuccess]);
  
  // Memoize the notes display to prevent unnecessary re-renders
  const notesDisplay = useMemo(() => {
    if (displayNotes.length === 0) {
      return 'Start playing!';
    }
    return displayNotes.join(' • ');
  }, [displayNotes]);
  
  // Update the format function for copying
  const formatChordHistory = (history: ChordHistoryItem[]) => {
    if (!history || history.length === 0) return '';
    
    return [...history]
      .map(chord => `${chord.name} [${chord.notes.join(' • ')}]`)
      .reverse()
      .join(' → ');
  };
  
  // Function to handle dialog close with animation
  const closeDialog = useCallback(() => {
    setIsClosing(true);
    // Wait for animation to complete before hiding the dialog
    setTimeout(() => {
      setIsClosing(false);
      setShowConfirmDialog(false);
    }, 280);
  }, []);
  
  // Copy function to be called when confirmed
  const copyToClipboard = useCallback(() => {
    const formattedHistory = formatChordHistory(chordHistory);
    if (formattedHistory) {
      navigator.clipboard.writeText(formattedHistory)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 2000);
        })
        .catch(err => console.error('Failed to copy text: ', err));
    }
    closeDialog();
  }, [chordHistory, closeDialog]);
  
  // New function to create and download a MIDI file from chord history
  const downloadMidi = useCallback(() => {
    if (chordHistory.length === 0) return;
    
    try {
      // Create a simple map of our note format to the MIDI note name format
      // Shift all notes one octave higher for better MIDI playback
      const noteMap: Record<string, string> = {
        // C notes - shifted up one octave
        'C1': 'C2', 'C#1': 'C#2', 'Db1': 'C#2',
        'C2': 'C3', 'C#2': 'C#3', 'Db2': 'C#3',
        'C3': 'C4', 'C#3': 'C#4', 'Db3': 'C#4',
        'C4': 'C5', 'C#4': 'C#5', 'Db4': 'C#5',
        'C5': 'C6', 'C#5': 'C#6', 'Db5': 'C#6',
        'C6': 'C7', 'C#6': 'C#7', 'Db6': 'C#7',
        
        // D notes - shifted up one octave
        'D1': 'D2', 'D#1': 'D#2', 'Eb1': 'D#2',
        'D2': 'D3', 'D#2': 'D#3', 'Eb2': 'D#3',
        'D3': 'D4', 'D#3': 'D#4', 'Eb3': 'D#4',
        'D4': 'D5', 'D#4': 'D#5', 'Eb4': 'D#5',
        'D5': 'D6', 'D#5': 'D#6', 'Eb5': 'D#6',
        'D6': 'D7', 'D#6': 'D#7', 'Eb6': 'D#7',
        
        // E notes - shifted up one octave
        'E1': 'E2', 'Fb1': 'E2',
        'E2': 'E3', 'Fb2': 'E3',
        'E3': 'E4', 'Fb3': 'E4',
        'E4': 'E5', 'Fb4': 'E5',
        'E5': 'E6', 'Fb5': 'E6',
        'E6': 'E7', 'Fb6': 'E7',
        
        // F notes - shifted up one octave
        'F1': 'F2', 'F#1': 'F#2', 'Gb1': 'F#2',
        'F2': 'F3', 'F#2': 'F#3', 'Gb2': 'F#3',
        'F3': 'F4', 'F#3': 'F#4', 'Gb3': 'F#4',
        'F4': 'F5', 'F#4': 'F#5', 'Gb4': 'F#5',
        'F5': 'F6', 'F#5': 'F#6', 'Gb5': 'F#6',
        'F6': 'F7', 'F#6': 'F#7', 'Gb6': 'F#7',
        
        // G notes - shifted up one octave
        'G1': 'G2', 'G#1': 'G#2', 'Ab1': 'G#2',
        'G2': 'G3', 'G#2': 'G#3', 'Ab2': 'G#3',
        'G3': 'G4', 'G#3': 'G#4', 'Ab3': 'G#4',
        'G4': 'G5', 'G#4': 'G#5', 'Ab4': 'G#5',
        'G5': 'G6', 'G#5': 'G#6', 'Ab5': 'G#6',
        'G6': 'G7', 'G#6': 'G#7', 'Ab6': 'G#7',
        
        // A notes - shifted up one octave
        'A1': 'A2', 'A#1': 'A#2', 'Bb1': 'A#2',
        'A2': 'A3', 'A#2': 'A#3', 'Bb2': 'A#3',
        'A3': 'A4', 'A#3': 'A#4', 'Bb3': 'A#4',
        'A4': 'A5', 'A#4': 'A#5', 'Bb4': 'A#5',
        'A5': 'A6', 'A#5': 'A#6', 'Bb5': 'A#6',
        'A6': 'A7', 'A#6': 'A#7', 'Bb6': 'A#7',
        
        // B notes - shifted up one octave
        'B1': 'B2', 'Cb2': 'B2',
        'B2': 'B3', 'Cb3': 'B3',
        'B3': 'B4', 'Cb4': 'B4',
        'B4': 'B5', 'Cb5': 'B5',
        'B5': 'B6', 'Cb6': 'B6',
        'B6': 'B7'
      };
      
      // Create a new MIDI file
      const midi = new Midi();
      midi.header.setTempo(120);
      
      // Add a track
      const track = midi.addTrack();
      track.name = "Chord Progression";
      track.instrument.name = "Piano";
      
      // Process the chord history in chronological order
      const chronologicalHistory = [...chordHistory].reverse();
      
      // Variables to track note additions and timing
      let hasAddedNotes = false;
      const defaultDuration = 1.0; // Fallback duration if none calculated
      const minDuration = 0.3; // Minimum duration to prevent very short notes
      let currentTime = 0; // Starting time position
      
      // Add each chord to the MIDI file
      chronologicalHistory.forEach((chord, chordIndex) => {
        // Skip empty chords
        if (!chord.notes || chord.notes.length === 0) {
          return;
        }
        
        // Calculate duration for this chord - use actual duration if available
        // Or default duration for the last chord or if duration is too short
        const isLastChord = chordIndex === chronologicalHistory.length - 1;
        const effectiveDuration = isLastChord || !chord.duration || chord.duration < minDuration * 1000 
          ? defaultDuration 
          : Math.min(chord.duration / 1000, 4.0); // Convert ms to seconds, cap at 4 seconds max
        
        // Handle unusually long pauses - if longer than 4 seconds, cap it
        // This prevents the MIDI file from having extremely long gaps
        const cappedEffectiveDuration = Math.min(effectiveDuration, 4.0);
        
        // Process each note in the chord
        chord.notes.forEach((noteStr, noteIndex) => {
          // Clean up the note string
          const cleanNoteStr = noteStr.trim();
          
          // Get the standardized note name
          const midiNoteName = noteMap[cleanNoteStr];
          
          if (midiNoteName) {
            // Create note timing variations for natural sound
            const noteOffset = noteIndex * 0.02; // 20ms stagger between notes
            const velocity = 0.8 - (noteIndex * 0.05); // Reduce velocity for lower notes
            
            try {
              // Add the note with correct time and duration
              track.addNote({
                name: midiNoteName,
                time: currentTime + noteOffset,
                duration: cappedEffectiveDuration - 0.05, // Slightly shorter than the chord duration
                velocity: velocity
              });
              hasAddedNotes = true;
            } catch (error) {
              console.warn(`Failed to add note ${cleanNoteStr} (${midiNoteName}):`, error);
            }
          } else {
            console.warn(`Note not found in mapping: ${cleanNoteStr}`);
          }
        });
        
        // Move time position forward by this chord's duration
        currentTime += cappedEffectiveDuration;
      });
      
      // If no notes were successfully added, add a simple C major chord as fallback
      if (!hasAddedNotes) {
        console.log("No valid notes were added, creating fallback C major chord");
        
        try {
          // Add fallback notes with the correct parameter format (using name)
          track.addNote({
            name: "C5",
            time: 0.00,
            duration: 1.0,
            velocity: 0.8
          });
          
          track.addNote({
            name: "E5",
            time: 0.02,
            duration: 1.0,
            velocity: 0.75
          });
          
          track.addNote({
            name: "G5",
            time: 0.04,
            duration: 1.0,
            velocity: 0.7
          });
        } catch (error) {
          console.error("Failed to add fallback notes:", error);
          throw new Error("Could not create fallback notes for MIDI file");
        }
      }
      
      // Verify that notes were added
      if (!track.notes || track.notes.length === 0) {
        throw new Error('No valid notes found to create MIDI file');
      }
      
      // Convert to a Blob
      const midiData = midi.toArray();
      const blob = new Blob([midiData], { type: 'audio/midi' });
      
      // Create and trigger download
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `chord-progression-${new Date().toISOString().slice(0, 10)}.mid`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the object URL
      setTimeout(() => URL.revokeObjectURL(url), 100);
      
      // Show success message
      setMidiSuccess(true);
      setTimeout(() => setMidiSuccess(false), 2000);
      
      // Close the dialog
      closeDialog();
    } catch (error) {
      console.error('Failed to create MIDI file:', error);
      alert('Failed to create MIDI file. Please try again.');
    }
  }, [chordHistory, closeDialog]);
  
  // Render countdown or regular content
  return (
    <div className="note-display">
      {countdown !== null ? (
        <div className="countdown-container">
          <div className="countdown-number">{countdown}</div>
        </div>
      ) : (
        <>
          <div className='notes-graph' ref={notesGraphRef}> 
            <div className='flats'>
              <div className='flat-0' id='Db1'></div>
              <div className='flat-2' id='Eb1'></div>
              <div className='flat-1' id='Gb1'></div>
              <div className='flat-2' id='Ab1'></div>
              <div className='flat-2' id='Bb1'></div>
              <div className='flat-1' id='Db2'></div>
              <div className='flat-2' id='Eb2'></div>
              <div className='flat-1' id='Gb2'></div>
              <div className='flat-2' id='Ab2'></div>
              <div className='flat-2' id='Bb2'></div>
              <div className='flat-1' id='Db3'></div>
              <div className='flat-2' id='Eb3'></div>
              <div className='flat-1' id='Gb3'></div>
              <div className='flat-2' id='Ab3'></div>
              <div className='flat-2' id='Bb3'></div>
              <div className='flat-1' id='Db4'></div>
              <div className='flat-2' id='Eb4'></div>
              <div className='flat-1' id='Gb4'></div>
              <div className='flat-2' id='Ab4'></div>
              <div className='flat-2' id='Bb4'></div>
              <div className='flat-1' id='Db5'></div>
              <div className='flat-2' id='Eb5'></div>
              <div className='flat-1' id='Gb5'></div>
              <div className='flat-2' id='Ab5'></div>
              <div className='flat-2' id='Bb5'></div>
              <div className='flat-1' id='Db6'></div>
              <div className='flat-2' id='Eb6'></div>
              <div className='flat-1' id='Gb6'></div>
              <div className='flat-2' id='Ab6'></div>
              <div className='flat-2' id='Bb6'></div>
            </div>
            <div className='sharps'> 
              <div className='sharp-1' id='C1'></div>
              <div className='sharp-1' id='D1'></div>
              <div className='sharp-1' id='E1'></div>
              <div className='sharp-1' id='F1'></div>
              <div className='sharp-1' id='G1'></div>
              <div className='sharp-1' id='A1'></div>
              <div className='sharp-1' id='B1'></div>
              <div className='sharp-1' id='C2'></div>
              <div className='sharp-1' id='D2'></div>
              <div className='sharp-1' id='E2'></div>
              <div className='sharp-1' id='F2'></div>
              <div className='sharp-1' id='G2'></div>
              <div className='sharp-1' id='A2'></div>
              <div className='sharp-1' id='B2'></div>
              <div className='sharp-1' id='C3'></div>
              <div className='sharp-1' id='D3'></div>
              <div className='sharp-1' id='E3'></div>
              <div className='sharp-1' id='F3'></div>
              <div className='sharp-1' id='G3'></div>
              <div className='sharp-1' id='A3'></div>
              <div className='sharp-1' id='B3'></div>
              <div className='sharp-1' id='C4'></div>
              <div className='sharp-1' id='D4'></div>
              <div className='sharp-1' id='E4'></div>
              <div className='sharp-1' id='F4'></div>
              <div className='sharp-1' id='G4'></div>
              <div className='sharp-1' id='A4'></div>
              <div className='sharp-1' id='B4'></div>
              <div className='sharp-1' id='C5'></div>
              <div className='sharp-1' id='D5'></div>
              <div className='sharp-1' id='E5'></div>
              <div className='sharp-1' id='F5'></div>
              <div className='sharp-1' id='G5'></div>
              <div className='sharp-1' id='A5'></div>
              <div className='sharp-1' id='B5'></div>
              <div className='sharp-1' id='C6'></div>
              <div className='sharp-1' id='D6'></div>
              <div className='sharp-1' id='E6'></div>
              <div className='sharp-1' id='F6'></div>
              <div className='sharp-1' id='G6'></div>
              <div className='sharp-1' id='A6'></div>
              <div className='sharp-1' id='B6'></div>
            </div>
          </div>

          <div className="chord-history-container">
            {chordHistoryDisplay}
          </div>
          
          {/* Copy confirmation dialog - using Portal to render at document root */}
          {showConfirmDialog && ReactDOM.createPortal(
            <div 
              className={`confirmation-overlay ${isClosing ? 'closing' : ''}`}
              onClick={(e) => {
                // Close the dialog if the click is on the overlay itself, not the dialog
                if (e.target === e.currentTarget) {
                  closeDialog();
                }
              }}
            >
              <div className={`confirmation-dialog ${isClosing ? 'closing' : ''}`}>
                <div>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="#010000" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 13.334C8.75 12.6436 9.30964 12.084 10 12.084H16.6667C17.357 12.084 17.9167 12.6436 17.9167 13.334C17.9167 14.0243 17.357 14.584 16.6667 14.584H10C9.30964 14.584 8.75 14.0243 8.75 13.334ZM22.083 13.334C22.083 12.6436 22.6427 12.084 23.333 12.084H23.3497C24.04 12.084 24.5997 12.6436 24.5997 13.334C24.5997 14.0243 24.04 14.584 23.3497 14.584H23.333C22.6427 14.584 22.083 14.0243 22.083 13.334ZM30 12.084C29.3096 12.084 28.75 12.6436 28.75 13.334C28.75 14.0243 29.3096 14.584 30 14.584H30.0167C30.707 14.584 31.2667 14.0243 31.2667 13.334C31.2667 12.6436 30.707 12.084 30.0167 12.084H30Z" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.58301 9.99935C4.58301 8.84876 5.51575 7.91602 6.66634 7.91602H33.333C34.4836 7.91602 35.4163 8.84876 35.4163 9.99935V18.75H30H23.333H16.667H10H4.58301V9.99935ZM28.75 21.25V26.6667C28.75 27.357 29.3096 27.9167 30 27.9167C30.6904 27.9167 31.25 27.357 31.25 26.6667V21.25H35.4163V29.9993C35.4163 31.1499 34.4836 32.0827 33.333 32.0827H6.66634C5.51575 32.0827 4.58301 31.1499 4.58301 29.9993V21.25H8.75V26.6667C8.75 27.357 9.30964 27.9167 10 27.9167C10.6904 27.9167 11.25 27.357 11.25 26.6667V21.25H15.417V26.6667C15.417 27.357 15.9766 27.9167 16.667 27.9167C17.3573 27.9167 17.917 27.357 17.917 26.6667V21.25H22.083V26.6667C22.083 27.357 22.6427 27.9167 23.333 27.9167C24.0234 27.9167 24.583 27.357 24.583 26.6667V21.25H28.75ZM2.08301 20V9.99935C2.08301 7.46804 4.13504 5.41602 6.66634 5.41602H33.333C35.8643 5.41602 37.9163 7.46804 37.9163 9.99935V20V29.9993C37.9163 32.5307 35.8643 34.5827 33.333 34.5827H6.66634C4.13504 34.5827 2.08301 32.5307 2.08301 29.9993V20Z" />
                  </svg>

                </div>
                <p>select the format to export chords</p>
                <div className="confirmation-actions">
                  <button 
                    className="confirm-copy-btn" 
                    onClick={copyToClipboard}
                  >
                    <span>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#010000" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.08333 3.33333C2.08333 2.64679 2.64679 2.08333 3.33333 2.08333H11.6667C12.3532 2.08333 12.9167 2.64679 12.9167 3.33333C12.9167 3.56345 13.1032 3.75 13.3333 3.75C13.5635 3.75 13.75 3.56345 13.75 3.33333C13.75 2.18655 12.8135 1.25 11.6667 1.25H3.33333C2.18655 1.25 1.25 2.18655 1.25 3.33333V11.6667C1.25 12.8135 2.18655 13.75 3.33333 13.75C3.56345 13.75 3.75 13.5635 3.75 13.3333C3.75 13.1032 3.56345 12.9167 3.33333 12.9167C2.64679 12.9167 2.08333 12.3532 2.08333 11.6667V3.33333ZM7.08333 8.33333C7.08333 7.64298 7.64298 7.08333 8.33333 7.08333H16.6667C17.357 7.08333 17.9167 7.64298 17.9167 8.33333V16.6667C17.9167 17.357 17.357 17.9167 16.6667 17.9167H8.33333C7.64298 17.9167 7.08333 17.357 7.08333 16.6667V8.33333ZM8.33333 6.25C7.18274 6.25 6.25 7.18274 6.25 8.33333V16.6667C6.25 17.8173 7.18274 18.75 8.33333 18.75H16.6667C17.8173 18.75 18.75 17.8173 18.75 16.6667V8.33333C18.75 7.18274 17.8173 6.25 16.6667 6.25H8.33333Z" />
                      </svg>
                    </span>
                    copy as text
                  </button>
                  <button 
                    className="confirm-copy-btn" 
                    onClick={downloadMidi}
                  >
                    <span>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#010000" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.875C10.3452 1.875 10.625 2.15482 10.625 2.5V12.6571L14.5581 8.72407C14.8021 8.48 15.1979 8.48 15.4419 8.72407C15.686 8.96815 15.686 9.36388 15.4419 9.60796L10.4586 14.5913C10.3445 14.7145 10.1812 14.7917 10 14.7917C9.81876 14.7917 9.65554 14.7145 9.54138 14.5913L4.55806 9.60796C4.31398 9.36388 4.31398 8.96815 4.55806 8.72407C4.80214 8.48 5.19786 8.48 5.44194 8.72407L9.375 12.6571V2.5C9.375 2.15482 9.65482 1.875 10 1.875ZM4.16699 16.875C3.82181 16.875 3.54199 17.1548 3.54199 17.5C3.54199 17.8452 3.82181 18.125 4.16699 18.125H15.8337C16.1788 18.125 16.4587 17.8452 16.4587 17.5C16.4587 17.1548 16.1788 16.875 15.8337 16.875H4.16699Z" />
                      </svg>
                    </span>
                    download MIDI
                  </button>
                  <button 
                    className="confirm-cancel-btn" 
                    onClick={closeDialog}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>,
            document.body
          )}
          
          <div className={`copy-notification ${copySuccess ? 'show' : ''}`}>
            copied to clipboard
          </div>
          
          <div className={`midi-notification ${midiSuccess ? 'show' : ''}`}>
            MIDI file downloaded
          </div>
        </>
      )}
    </div>
  );
}

// Wrap the component with React.memo to prevent unnecessary re-renders
export default React.memo(NoteDisplay); 