import { useState, useCallback, useRef } from 'react';
import { getNotes, getBorrowedNotes } from '../utils/musicTheory';

// Map of sharp notes to their flat equivalents
const SHARP_TO_FLAT_MAP: Record<string, string> = {
  'G#': 'Ab',
  'A#': 'Bb',
  'C#': 'Db',
  'D#': 'Eb',
  'F#': 'Gb'
};

// Map for safe file naming (avoiding special characters)
const SAFE_FILE_NAMES: Record<string, string> = {
  'C': 'C',
  'Db': 'Db',
  'D': 'D',
  'Eb': 'Eb',
  'E': 'E',
  'F': 'F',
  'Gb': 'Gb',
  'G': 'G',
  'Ab': 'Ab',
  'A': 'A',
  'Bb': 'Bb',
  'B': 'B'
};

export const useAudioEngine = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const audioContext = useRef<AudioContext | null>(null);
  const sampleBuffers = useRef<Record<string, AudioBuffer>>({});
  
  // Add these to your useAudioEngine hook
  const [activeNotes, setActiveNotes] = useState<Record<string, Record<string, {
    source: AudioBufferSourceNode;
    gain: GainNode;
  }>>>({});
  
  // Add this to store active chord sources
  const activeChordRefs = useRef<Record<string, Record<string, {
    source: AudioBufferSourceNode;
    gain: GainNode;
    startTime: number;
  }>>>({});
  
  // Add this to track all active sources for emergency cleanup
  const allActiveSources = useRef<Set<{
    source: AudioBufferSourceNode;
    gain: GainNode;
  }>>(new Set());
  
  // Add this to track the last time a chord was played
  const lastChordTimestamps = useRef<Record<string, number>>({});
  
  // Add a ref for the master gain node
  const masterGainNode = useRef<GainNode | null>(null);
  
  // Add a ref for the current master volume
  const currentVolume = useRef<number>(0.8); // Default to 80%
  
  // Initialize audio context on first user interaction
  const initAudioContext = useCallback(async () => {
    try {
      const context = new AudioContext();
      audioContext.current = context;
      return context;
    } catch (error) {
      console.error('Failed to initialize audio context:', error);
      throw error;
    }
  }, []);
  
  // Convert sharp notes to flat notation for sample loading
  const normalizeToFlatNotation = useCallback((noteWithOctave: string): string => {
    // Extract note name and octave
    const match = noteWithOctave.match(/([A-G][#]?)(\d)/);
    if (!match) return noteWithOctave;
    
    const [, noteName, octave] = match;
    
    // Convert sharp notes to flat equivalents
    let normalizedNoteName = noteName;
    if (noteName.includes('#')) {
      normalizedNoteName = SHARP_TO_FLAT_MAP[noteName] || noteName;
    }
    
    return `${normalizedNoteName}${octave}`;
  }, []);
  
  // Load all samples for an instrument
  const loadSamples = useCallback(async (instrument: string) => {
    try {
      // Clear existing buffers when loading new instrument
      sampleBuffers.current = {};
      
      // Reset audio context when loading new instrument
      if (audioContext.current) {
        audioContext.current.close();
      }
      const context = await initAudioContext();
      
      // Recreate master gain node with new context
      masterGainNode.current = context.createGain();
      masterGainNode.current.gain.value = currentVolume.current;
      masterGainNode.current.connect(context.destination);

      if (!context) return;
      
      setIsLoaded(false);
      
      // Define notes to load (using flat notation)
      const notesToLoad = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
      const octaves = [1, 2, 3, 4, 5, 6];
      
      const loadPromises = [];
      
      for (const octave of octaves) {
        for (const note of notesToLoad) {
          const noteId = `${note}${octave}`;
          const samplePath = `${process.env.PUBLIC_URL}/samples/${instrument}/${noteId}.mp3`;
          
          const loadPromise = fetch(samplePath)
            .then(response => {
              if (!response.ok) {
                console.warn(`Sample not found: ${samplePath}`);
                return null;
              }
              return response.arrayBuffer();
            })
            .then(arrayBuffer => {
              if (!arrayBuffer) return null;
              return context.decodeAudioData(arrayBuffer);  // Now context is properly typed
            })
            .then(audioBuffer => {
              if (!audioBuffer) return;
              
              // Store with the flat notation
              sampleBuffers.current[noteId] = audioBuffer;
              
              // Also store references for sharp equivalents
              Object.entries(SHARP_TO_FLAT_MAP).forEach(([sharp, flat]) => {
                if (flat === note) {
                  const sharpNoteId = `${sharp}${octave}`;
                  sampleBuffers.current[sharpNoteId] = audioBuffer;
                }
              });
            })
            .catch(error => {
              console.error(`Failed to load sample: ${samplePath}`, error);
            });
            
          loadPromises.push(loadPromise);
        }
      }
      
      await Promise.all(loadPromises);
      setIsLoaded(true);
    } catch (error) {
      console.error('Failed to load samples:', error);
    }
  }, [initAudioContext]);
  
  // Add this emergency stop function
  const stopAllSounds = useCallback(() => {
    if (!audioContext.current) return;
    
    // Stop all tracked sources
    allActiveSources.current.forEach(({ source, gain }) => {
      try {
        // Immediate stop
        gain.gain.cancelScheduledValues(audioContext.current!.currentTime);
        gain.gain.setValueAtTime(0, audioContext.current!.currentTime);
        source.stop(audioContext.current!.currentTime + 0.01);
        source.disconnect();
        gain.disconnect();
      } catch (error) {
        // Ignore errors from already stopped sources
      }
    });
    
    // Clear the set
    allActiveSources.current.clear();
    
    // Clear all active chord refs
    activeChordRefs.current = {};
    
    // Reset active notes state
    setActiveNotes({});
  }, [audioContext]);
  
  // Modify the playChord function to address both issues
  const playChord = useCallback((
    scale: string,
    degree: number,
    octave: number,
    chordType: string,
    voicing: string,
    isModalActive: boolean,
    isKeyDown: boolean = true,
    isBorrowed: boolean = false,
    predefinedNotes?: string[]
  ) => {
    if (!audioContext.current) {
      audioContext.current = new AudioContext();
      
      // Create master gain node if it doesn't exist
      if (!masterGainNode.current) {
        masterGainNode.current = audioContext.current.createGain();
        masterGainNode.current.gain.value = currentVolume.current;
        masterGainNode.current.connect(audioContext.current.destination);
      }
    }
    
    // Create a unique ID for this chord
    const chordId = `${scale}_${degree}_${octave}_${chordType}_${voicing}_${isModalActive}_${isBorrowed}`;
    
    // Get the current time
    const now = Date.now();
    
    // Check for rapid repeat presses (with an even shorter debounce time)
    if (isKeyDown) {
      const lastTimestamp = lastChordTimestamps.current[chordId] || 0;
      const timeSinceLastPlay = now - lastTimestamp;
      
      // Reduce debounce time from 50ms to 25ms for even more responsive playing
      if (timeSinceLastPlay < 25) {
        return;
      }
      
      // Update the timestamp for this chord
      lastChordTimestamps.current[chordId] = now;
    }
    
    // Get the notes for this chord
    const chordNotes = predefinedNotes || (isBorrowed ? 
      getBorrowedNotes({
        scale,
        degree,
        octave,
        chordType,
        voicing,
        isModalActive
      }) :
      getNotes({
        scale,
        degree,
        octave,
        chordType,
        voicing,
        isModalActive
      }));
    
    if (isKeyDown) {
      // Always stop any existing instance of this chord first
      const existingSources = activeChordRefs.current[chordId];
      if (existingSources) {
        try {
          Object.values(existingSources).forEach(noteData => {
            try {
              // Immediate stop
              noteData.source.stop(0);
              noteData.gain.gain.cancelScheduledValues(audioContext.current!.currentTime);
              noteData.gain.gain.setValueAtTime(0, audioContext.current!.currentTime);
              noteData.source.disconnect();
              noteData.gain.disconnect();
              
              // Remove from tracked sources
              allActiveSources.current.delete(noteData);
            } catch (e) {
              // Ignore errors from already stopped sources
            }
          });
        } catch (e) {
          console.error("Error stopping existing chord:", e);
        }
        
        // Immediately remove from refs to prevent stale references
        delete activeChordRefs.current[chordId];
      }
      
      // Key is pressed - start playing the chord
      const noteCount = chordNotes.length;
      const baseGain = 0.8;
      const compensationFactor = Math.max(0.5, 1 / Math.sqrt(noteCount));
      
      // Store the active notes for this chord
      const noteSources: Record<string, {
        source: AudioBufferSourceNode;
        gain: GainNode;
        startTime: number;
      }> = {};
      
      // Play each note in the chord
      chordNotes.forEach(noteId => {
        try {
          // Extract note name and octave from the note ID
          const match = noteId.match(/([A-G][b#]?)(\d+)/);
          if (!match) {
            console.error(`Invalid note format: ${noteId}`);
            return;
          }
          
          const [_, noteName, noteOctave] = match;
          
          // Find the closest available octave
          let closestOctave = parseInt(noteOctave);
          if (closestOctave < 2) closestOctave = 2;
          if (closestOctave > 5) closestOctave = 5;
          
          const transposedNoteId = `${noteName}${closestOctave}`;
          
          // Try to play the note with the closest available octave
          if (sampleBuffers.current[transposedNoteId]) {
            const source = audioContext.current!.createBufferSource();
            source.buffer = sampleBuffers.current[transposedNoteId];
            source.loop = false;
            
            const noteGain = audioContext.current!.createGain();
            noteGain.gain.value = baseGain * compensationFactor;
            
            // Apply detune if we had to transpose the octave
            const semitonesDiff = (parseInt(noteOctave) - closestOctave) * 12;
            if (semitonesDiff !== 0) {
              source.detune.value = semitonesDiff * 100;
            }
            
            if (!masterGainNode.current && audioContext.current) {
              masterGainNode.current = audioContext.current.createGain();
              masterGainNode.current.gain.value = currentVolume.current;
              masterGainNode.current.connect(audioContext.current.destination);
            }
            
            source.connect(noteGain);
            noteGain.connect(masterGainNode.current!);
            source.start();
            
            noteSources[noteId] = { source, gain: noteGain, startTime: now };
            return;
          }
          
          // If we couldn't find the note, try converting sharp to flat
          const flatNoteId = normalizeToFlatNotation(transposedNoteId);
          if (sampleBuffers.current[flatNoteId] && flatNoteId !== transposedNoteId) {
            const source = audioContext.current!.createBufferSource();
            source.buffer = sampleBuffers.current[flatNoteId];
            source.loop = false;
            
            const noteGain = audioContext.current!.createGain();
            noteGain.gain.value = baseGain * compensationFactor;
            
            // Apply detune if we had to transpose the octave
            const semitonesDiff = (parseInt(noteOctave) - closestOctave) * 12;
            if (semitonesDiff !== 0) {
              source.detune.value = semitonesDiff * 100;
            }
            
            if (!masterGainNode.current && audioContext.current) {
              masterGainNode.current = audioContext.current.createGain();
              masterGainNode.current.gain.value = currentVolume.current;
              masterGainNode.current.connect(audioContext.current.destination);
            }
            
            source.connect(noteGain);
            noteGain.connect(masterGainNode.current!);
            source.start();
            
            noteSources[noteId] = { source, gain: noteGain, startTime: now };
            return;
          }
          
          // If we still can't find the note, log an error
          console.error(`Could not find sample for note: ${noteId} or ${flatNoteId}`);
        } catch (error) {
          console.error(`Error playing note ${noteId}:`, error);
        }
      });
      
      // Store in the ref for direct access
      activeChordRefs.current[chordId] = noteSources;
      
      // Also store in state for compatibility
      setActiveNotes(prev => {
        const newNotes = { ...prev };
        newNotes[chordId] = noteSources;
        return newNotes;
      });
      
      // Set a shorter safety timeout (1 second instead of 3)
      setTimeout(() => {
        const currentSources = activeChordRefs.current[chordId];
        if (currentSources) {
          // Check if these are the same sources we started (by comparing startTime)
          const shouldStop = Object.values(currentSources).some(noteData => 
            noteData.startTime === now
          );
          
          if (shouldStop) {
            // Apply a quick fade out
            Object.values(currentSources).forEach(noteData => {
              try {
                const audioTime = audioContext.current!.currentTime;
                noteData.gain.gain.cancelScheduledValues(audioTime);
                noteData.gain.gain.setValueAtTime(noteData.gain.gain.value, audioTime);
                noteData.gain.gain.linearRampToValueAtTime(0.001, audioTime + 0.1);
                noteData.source.stop(audioTime + 0.15);
              } catch (e) {
                // Try an immediate stop if the scheduled stop fails
                try {
                  noteData.source.stop(0);
                  noteData.gain.gain.value = 0;
                } catch (err) {
                  // Ignore errors
                }
              }
            });
            
            // Remove from refs and state immediately
            delete activeChordRefs.current[chordId];
            setActiveNotes(prev => {
              const newActiveNotes = { ...prev };
              delete newActiveNotes[chordId];
              return newActiveNotes;
            });
          }
        }
      }, 1000); // 1 second maximum duration
    } else {
      // Key is released - stop the chord with a very short decay
      const chordSources = activeChordRefs.current[chordId];
      if (chordSources) {
        const audioTime = audioContext.current!.currentTime;
        const RELEASE_TIME = 0.2; // Reduce to 100ms for even faster response
        
        // Apply release to each note in the chord
        Object.values(chordSources).forEach((noteData) => {
          try {
            // Use the Web Audio API's scheduling for smoother decay
            const gain = noteData.gain;
            
            // Cancel any scheduled values
            gain.gain.cancelScheduledValues(audioTime);
            
            // Set current value
            gain.gain.setValueAtTime(gain.gain.value, audioTime);
            
            // Linear ramp down (more natural for piano-like sounds)
            gain.gain.linearRampToValueAtTime(0.001, audioTime + RELEASE_TIME);
            
            // Schedule the source to stop after the release
            noteData.source.stop(audioTime + RELEASE_TIME + 0.05);
            
            // Remove from tracked sources after release
            setTimeout(() => {
              allActiveSources.current.delete(noteData);
            }, RELEASE_TIME * 1000 + 100);
          } catch (error) {
            console.error("Error applying release:", error);
            
            // Try to stop immediately if there was an error
            try {
              noteData.source.stop();
              noteData.gain.gain.value = 0;
            } catch (e) {
              // Ignore
            }
          }
        });
        
        // Remove from our ref immediately to prevent stale references
        delete activeChordRefs.current[chordId];
        
        // Also remove from state for compatibility
        setActiveNotes(prev => {
          const newActiveNotes = { ...prev };
          delete newActiveNotes[chordId];
          return newActiveNotes;
        });
      }
    }
  }, [normalizeToFlatNotation, sampleBuffers, audioContext]);
  
  // Add this to your useAudioEngine hook
  const playTestNote = useCallback(() => {
    if (!audioContext.current) {
      audioContext.current = new AudioContext();
      
      // Create master gain node if it doesn't exist
      if (!masterGainNode.current) {
        masterGainNode.current = audioContext.current.createGain();
        masterGainNode.current.gain.value = currentVolume.current;
        masterGainNode.current.connect(audioContext.current.destination);
      }
    }
    
    // Create a simple oscillator as a fallback
    const oscillator = audioContext.current.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.value = 440; // A4
    
    const gainNode = audioContext.current.createGain();
    gainNode.gain.setValueAtTime(0.25, audioContext.current.currentTime); // Reduce to 50% of 0.5 = 0.25
    gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.current.currentTime + 1);
    
    oscillator.connect(gainNode);
    gainNode.connect(masterGainNode.current!);
    
    oscillator.start();
    oscillator.stop(audioContext.current.currentTime + 1);
  }, []);
  
  // Fix the playSpecificNote function to be more reliable
  const playSpecificNote = useCallback((note: string, instrumentName: string = 'piano') => {
    if (!audioContext.current) {
      console.error('Audio context not initialized');
      initAudioContext(); // Initialize if needed
      return; // Return early to avoid issues
    }
    
    console.log(`Attempting to play specific note: ${note}`);
    
    // Check if we have any samples loaded
    const availableSamples = Object.keys(sampleBuffers.current);
    if (availableSamples.length === 0) {
      console.error('No samples loaded. Try initializing audio first.');
      playTestNote(); // Fall back to the test note which uses an oscillator
      return;
    }
    
    console.log('Available samples:', availableSamples.sort());
    
    // Try different case variations
    const variations = [
      note,
      note.toLowerCase(),
      note.toUpperCase(),
      `${instrumentName}_${note}`
    ];
    
    // Try each variation
    for (const variant of variations) {
      if (sampleBuffers.current[variant]) {
        console.log(`Sample found for variant ${variant}, playing...`);
        const sampleSource = audioContext.current.createBufferSource();
        sampleSource.buffer = sampleBuffers.current[variant];
        sampleSource.connect(masterGainNode.current!);
        sampleSource.start();
        return; // Exit if we found a match
      }
    }
    
    // If we get here, no variation was found - use the simple test note
    console.error(`Sample not found for note: ${note}. Playing test note instead.`);
    playTestNote();
  }, [audioContext, sampleBuffers, initAudioContext, playTestNote]);
  
  // Add this function to your useAudioEngine hook
  const getSampleKeys = useCallback((): string[] => {
    // Return the keys of the sampleBuffers object
    return Object.keys(sampleBuffers.current);
  }, [sampleBuffers]);
  
  // Fix the diagnoseAudio function to handle null audioContext
  const diagnoseAudio = useCallback(() => {
    console.log("=== AUDIO SYSTEM DIAGNOSIS ===");
    console.log(`Audio Context exists: ${!!audioContext.current}`);
    
    if (audioContext.current) {
      console.log(`Audio Context state: ${audioContext.current.state}`);
      console.log(`Sample Rate: ${audioContext.current.sampleRate}`);
      console.log(`Current Time: ${audioContext.current.currentTime}`);
    }
    
    const sampleCount = Object.keys(sampleBuffers.current).length;
    console.log(`Loaded samples: ${sampleCount}`);
    
    if (sampleCount > 0) {
      const sampleKeys = Object.keys(sampleBuffers.current).sort();
      console.log(`First 10 samples: ${sampleKeys.slice(0, 10).join(', ')}`);
      
      // Check if a sample buffer is valid
      const firstSample = sampleBuffers.current[sampleKeys[0]];
      console.log(`First sample duration: ${firstSample ? firstSample.duration : 'N/A'} seconds`);
      console.log(`First sample channels: ${firstSample ? firstSample.numberOfChannels : 'N/A'}`);
    }
    
    // Try to play a simple tone to test audio output
    try {
      if (!audioContext.current) {
        console.log("Cannot play test tone: Audio context is null");
        return {
          contextExists: false,
          contextState: null,
          sampleCount,
          hasSamples: sampleCount > 0
        };
      }
      
      const testOsc = audioContext.current.createOscillator();
      testOsc.type = 'sine';
      testOsc.frequency.value = 440;
      
      const testGain = audioContext.current.createGain();
      testGain.gain.value = 0.2;
      
      testOsc.connect(testGain);
      testGain.connect(masterGainNode.current!);
      
      testOsc.start();
      testOsc.stop(audioContext.current.currentTime + 0.5);
    } catch (error) {
      console.error("Error playing test tone:", error);
    }
    
    console.log("=== END DIAGNOSIS ===");
    
    return {
      contextExists: !!audioContext.current,
      contextState: audioContext.current?.state,
      sampleCount,
      hasSamples: sampleCount > 0
    };
  }, [audioContext, sampleBuffers]);
  
  // Add this test function to your useAudioEngine hook
  const testIdRef = useRef<string | null>(null);
  const testSourceRef = useRef<{
    source: OscillatorNode;
    gain: GainNode;
  } | null>(null);

  const testStopSound = useCallback(() => {
    if (!audioContext.current) {
      console.error('Audio context not initialized');
      return;
    }
    
    console.log("Testing sound stop functionality...");
    
    // Create a unique ID for this test and store in ref
    const testId = `test_${Date.now()}`;
    testIdRef.current = testId;
    
    // Create a source and gain node
    const source = audioContext.current.createOscillator();
    source.type = 'sine';
    source.frequency.value = 440; // A4
    
    const gain = audioContext.current.createGain();
    gain.gain.value = 0.3;
    
    source.connect(gain);
    gain.connect(masterGainNode.current!);
    
    // Store the source and gain in a ref for direct access
    testSourceRef.current = { source, gain };
    
    // Start the sound
    source.start();
    
    // Return a function to stop the sound
    return () => {
      console.log("Attempting to stop test sound...");
      
      // Use the stored source and gain directly from the ref
      const testSource = testSourceRef.current;
      if (testSource) {
        try {
          console.log("Stopping source directly from ref");
          
          // Stop the source
          testSource.source.stop(0);
          testSource.gain.gain.setValueAtTime(0, audioContext.current!.currentTime);
          testSource.source.disconnect();
          testSource.gain.disconnect();
          
          // Clear the refs
          testIdRef.current = null;
          testSourceRef.current = null;
          
          console.log("Test sound stopped successfully");
        } catch (error) {
          console.error("Error stopping source:", error);
        }
      } else {
        console.error("No test source found in ref");
      }
    };
  }, [audioContext]);
  
  // Set master volume - properly implemented to affect all sounds
  const setMasterVolume = useCallback((volume: number) => {
    console.log(`Setting master volume to ${volume}`);
    currentVolume.current = volume;
    
    if (audioContext.current && masterGainNode.current) {
      // Apply volume with a small ramp to avoid clicks
      const now = audioContext.current.currentTime;
      masterGainNode.current.gain.cancelScheduledValues(now);
      masterGainNode.current.gain.setValueAtTime(masterGainNode.current.gain.value, now);
      masterGainNode.current.gain.linearRampToValueAtTime(volume, now + 0.05);
    } else {
      console.warn('Cannot set volume: audio context or master gain node not initialized');
    }
  }, []);
  
  return {
    isLoaded,
    loadSamples,
    playChord,
    initAudioContext,
    playTestNote,
    playSpecificNote,
    getSampleKeys,
    diagnoseAudio,
    testStopSound,
    stopAllSounds,
    setMasterVolume
  };
}; 