import { useState, useEffect, useRef, useCallback } from 'react';

export const useLoopPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentGenre, setCurrentGenre] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [loopDuration, setLoopDuration] = useState(0);
  const [audioReady, setAudioReady] = useState(false);
  const [initialized, setInitialized] = useState(false);
  
  // Use Web Audio API for gapless looping
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceNodesRef = useRef<AudioBufferSourceNode[]>([]);
  const gainNodeRef = useRef<GainNode | null>(null);
  const bufferRef = useRef<AudioBuffer | null>(null);
  const startTimeRef = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);
  const userTriggeredRef = useRef<boolean>(false);
  const scheduledSourcesRef = useRef<{source: AudioBufferSourceNode, startTime: number}[]>([]);
  const shouldPlayAfterLoadRef = useRef<boolean>(false);
  
  // Add a ref for the current volume
  const currentVolume = useRef<number>(0.5); // Increased from 0.3 to 0.5 (50% volume)
  
  // Helper to stop all audio sources
  const stopAllSources = useCallback(() => {
    // Stop all scheduled sources
    scheduledSourcesRef.current.forEach(({source}) => {
      try {
        source.stop();
        source.disconnect();
      } catch (e) {
        // Ignore errors from already stopped sources
      }
    });
    scheduledSourcesRef.current = [];
    
    // Clear source nodes array
    sourceNodesRef.current = [];
  }, []);
  
  // Stop the loop
  const stopLoop = useCallback(() => {
    try {
      if (audioContextRef.current?.state === 'suspended') {
        audioContextRef.current.resume();
      }
      
      // Stop all sources
      stopAllSources();
      
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
      
      setIsPlaying(false);
      setProgress(0);
    } catch (error) {
      console.error("Failed to stop loop:", error);
    }
  }, [stopAllSources]);
  
  // Schedule multiple loop iterations in advance for gapless playback
  const scheduleLoops = useCallback((startTime: number, count: number) => {
    if (!audioContextRef.current || !bufferRef.current || !gainNodeRef.current) return;
    
    const buffer = bufferRef.current;
    const duration = buffer.duration;
    
    for (let i = 0; i < count; i++) {
      // Create a new source node
      const source = audioContextRef.current.createBufferSource();
      source.buffer = buffer;
      
      // Connect to gain node
      source.connect(gainNodeRef.current);
      
      // Calculate precise start time for this iteration
      const iterationStartTime = startTime + (i * duration);
      
      // Schedule playback
      source.start(iterationStartTime);
      
      // Store the source and its scheduled start time
      scheduledSourcesRef.current.push({
        source,
        startTime: iterationStartTime
      });
      
      // Add to active sources
      sourceNodesRef.current.push(source);
    }
    
    console.log(`Scheduled ${count} loop iterations starting at ${startTime}`);
  }, []);
  
  // Improved playback with better scheduling
  const playLoop = useCallback(async () => {
    if (!initialized) {
      console.log("Cannot play loop: audio not initialized");
      return;
    }
    
    if (!audioContextRef.current || !bufferRef.current || !gainNodeRef.current || !audioReady) {
      console.log("Cannot play loop: audio not ready");
      return;
    }
    
    try {
      // Check if audio context is in suspended state
      if (audioContextRef.current?.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      // If context is null or closed, reinitialize it
      if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
        audioContextRef.current = new AudioContext();
        // Reinitialize any necessary audio nodes
        if (!gainNodeRef.current) {
          gainNodeRef.current = audioContextRef.current.createGain();
          gainNodeRef.current.connect(audioContextRef.current.destination);
        }
      }

      // Stop any currently playing sources
      stopAllSources();
      
      // Get current audio context time
      const startTime = audioContextRef.current.currentTime;
      startTimeRef.current = startTime;
      
      // Schedule multiple loop iterations for gapless playback
      scheduleLoops(startTime, 3);
      
      // Start the animation timer
      const animStartTime = performance.now();
      const duration = bufferRef.current.duration * 1000; // Convert to milliseconds
      
      const updateProgress = () => {
        if (!isPlaying) return;
        
        const now = performance.now();
        const elapsed = (now - animStartTime) % duration;
        const newProgress = elapsed / duration;
        
        // Force a re-render by creating a new value
        setProgress(newProgress);
        
        // Continue the animation loop
        animationFrameRef.current = requestAnimationFrame(updateProgress);
      };
      
      // Start the animation immediately
      animationFrameRef.current = requestAnimationFrame(updateProgress);
      setIsPlaying(true);
    } catch (error) {
      console.error("Failed to initialize audio context:", error);
      setIsPlaying(false);
    }
  }, [audioReady, isPlaying, initialized, scheduleLoops, stopAllSources]);
  
  // Improved buffer loading with precise duration measurement
  const loadAudioBuffer = useCallback(async (genre: string) => {
    if (!audioContextRef.current || !initialized) {
      console.log("Cannot load audio buffer: audio context not initialized");
      return;
    }
    
    try {
      setAudioReady(false);
      
      // Format the genre name to match the file naming convention
      const formattedGenre = genre.toLowerCase().replace(/\s+/g, '');
      const loopPath = `${process.env.PUBLIC_URL}/loops/${formattedGenre}.mp3`;
      
      console.log(`Loading loop file: ${loopPath} for genre: ${genre}`);
      
      // Fetch the audio file
      const response = await fetch(loopPath);
      if (!response.ok) {
        console.error(`Failed to fetch loop: ${response.status} ${response.statusText} for path: ${loopPath}`);
        throw new Error(`Failed to fetch loop: ${response.status} ${response.statusText}`);
      }
      
      const arrayBuffer = await response.arrayBuffer();
      
      // Decode the audio data
      const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
      
      // Store the buffer
      bufferRef.current = audioBuffer;
      
      // Get precise duration
      const preciseDuration = audioBuffer.duration;
      console.log(`Successfully loaded loop for ${genre}, duration: ${preciseDuration.toFixed(3)}s`);
      
      // Store the loop duration for animation
      setLoopDuration(preciseDuration);
      setAudioReady(true);
      
      // Check if we should play after loading
      if (shouldPlayAfterLoadRef.current && userTriggeredRef.current) {
        console.log("Playing loop after buffer load (user triggered)");
        shouldPlayAfterLoadRef.current = false;
        
        // We need to stop any existing playback first
        if (isPlaying) {
          stopAllSources();
          if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = null;
          }
        }
        
        // Now play the new buffer
        if (audioContextRef.current && bufferRef.current && gainNodeRef.current) {
          const startTime = audioContextRef.current.currentTime;
          startTimeRef.current = startTime;
          scheduleLoops(startTime, 3);
          
          // Start the animation timer
          const animStartTime = performance.now();
          const duration = bufferRef.current.duration * 1000; // Convert to milliseconds
          
          const updateProgress = () => {
            const now = performance.now();
            const elapsed = (now - animStartTime) % duration;
            const newProgress = elapsed / duration;
            setProgress(newProgress);
            animationFrameRef.current = requestAnimationFrame(updateProgress);
          };
          
          animationFrameRef.current = requestAnimationFrame(updateProgress);
          setIsPlaying(true);
        }
      }
    } catch (error) {
      console.error('Error loading audio buffer:', error);
      setAudioReady(false);
    }
  }, [isPlaying, initialized, scheduleLoops, stopAllSources]);
  
  // Method to initialize the audio context - should be called from App
  const initializeAudio = useCallback(() => {
    console.log("initializeAudio called in useLoopPlayer");
    
    if (audioContextRef.current) {
      console.log("Audio context already initialized");
      setInitialized(true);
      return;
    }
    
    try {
      console.log("Creating new AudioContext for loop player");
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      
      // Create gain node for volume control
      if (audioContextRef.current) {
        console.log("Creating gain node for loop player");
        gainNodeRef.current = audioContextRef.current.createGain();
        gainNodeRef.current.gain.value = currentVolume.current;
        gainNodeRef.current.connect(audioContextRef.current.destination);
        
        console.log("Loop player audio initialized successfully");
        setInitialized(true);
        
        // Load the current genre if one is set, or default to 'pop'
        if (currentGenre) {
          console.log(`Loading audio for current genre: ${currentGenre}`);
          loadAudioBuffer(currentGenre);
        } else {
          // Default to 'pop' if no genre is set
          const defaultGenre = 'pop';
          console.log(`No genre set, defaulting to: ${defaultGenre}`);
          setCurrentGenre(defaultGenre);
          loadAudioBuffer(defaultGenre);
        }
      }
    } catch (error) {
      console.error("Failed to initialize audio context:", error);
      setInitialized(false);
    }
  }, [currentGenre, loadAudioBuffer]);
  
  // Initialize audio context - but don't create it yet
  useEffect(() => {
    return () => {
      // Clean up
      stopAllSources();
      
      if (gainNodeRef.current) {
        gainNodeRef.current.disconnect();
        gainNodeRef.current = null;
      }
      
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      
      if (audioContextRef.current) {
        audioContextRef.current.close().catch(e => console.error("Error closing audio context:", e));
        audioContextRef.current = null;
      }
    };
  }, [stopAllSources]);
  
  // Check and schedule more loops if needed
  const checkAndScheduleMoreLoops = useCallback(() => {
    if (!audioContextRef.current || !isPlaying) return;
    
    const currentTime = audioContextRef.current.currentTime;
    const buffer = bufferRef.current;
    if (!buffer) return;
    
    const duration = buffer.duration;
    
    // Filter out sources that have already played
    scheduledSourcesRef.current = scheduledSourcesRef.current.filter(
      ({startTime}) => startTime + duration > currentTime
    );
    
    // If we have fewer than 2 upcoming sources, schedule more
    if (scheduledSourcesRef.current.length < 2) {
      let nextStartTime = currentTime;
      
      // If we have any scheduled sources, use the last one's end time
      if (scheduledSourcesRef.current.length > 0) {
        const lastScheduled = scheduledSourcesRef.current[scheduledSourcesRef.current.length - 1];
        nextStartTime = lastScheduled.startTime + duration;
      }
      
      // Schedule 3 more iterations
      scheduleLoops(nextStartTime, 3);
    }
  }, [isPlaying, scheduleLoops]);
  
  // Set up a regular interval to check and schedule more loops
  useEffect(() => {
    if (!isPlaying) return;
    
    const intervalId = setInterval(() => {
      checkAndScheduleMoreLoops();
    }, 1000); // Check every second
    
    return () => clearInterval(intervalId);
  }, [isPlaying, checkAndScheduleMoreLoops]);
  
  // Toggle play/pause with user trigger tracking
  const togglePlay = useCallback(() => {
    console.log("togglePlay called in useLoopPlayer");
    
    // Mark this as user-triggered
    userTriggeredRef.current = true;
    
    if (!initialized) {
      console.error("Cannot toggle play: audio not initialized");
      return;
    }
    
    if (!audioContextRef.current) {
      console.error("Audio context not initialized");
      return;
    }
    
    // Resume audio context if it's suspended (needed for some browsers)
    if (audioContextRef.current.state === 'suspended') {
      console.log('Resuming suspended audio context');
      audioContextRef.current.resume().catch(e => console.error("Error resuming audio context:", e));
    }
    
    // Force audio to be ready when user tries to play
    if (!audioReady) {
      console.log("Forcing audio ready state on user interaction");
      setAudioReady(true);
    }
    
    // Try to load the buffer if needed
    if (!bufferRef.current && currentGenre) {
      console.log("Loading audio buffer before playing");
      shouldPlayAfterLoadRef.current = !isPlaying; // Set flag to play after loading
      loadAudioBuffer(currentGenre);
      // Don't start playing yet, wait for the buffer to load
      return;
    }
    
    if (isPlaying) {
      console.log("Stopping loop");
      stopLoop();
    } else {
      console.log("Starting loop");
      playLoop();
    }
  }, [isPlaying, currentGenre, audioReady, loadAudioBuffer, playLoop, stopLoop, initialized]);
  
  // Update genre with safety checks
  const setGenre = useCallback((genre: string) => {
    if (genre === currentGenre) return;
    
    console.log(`Changing genre to: ${genre}`);
    setCurrentGenre(genre);
    
    // Only load audio if initialized
    if (initialized) {
      loadAudioBuffer(genre);
    }
  }, [currentGenre, loadAudioBuffer, initialized]);
  
  // Remove or modify the timeout that forces audioReady
  // Instead of automatically setting audioReady, just log a message
  useEffect(() => {
    if (initialized && !audioReady && currentGenre) {
      // If audio is initialized but not ready after 3 seconds, just log a message
      const timeoutId = setTimeout(() => {
        console.log("Audio still not ready after timeout - user interaction needed");
        // Don't force audioReady here
      }, 3000);
      
      return () => clearTimeout(timeoutId);
    }
  }, [initialized, audioReady, currentGenre]);
  
  // Set volume function
  const setVolume = useCallback((volume: number) => {
    if (gainNodeRef.current && audioContextRef.current) {
      console.log(`Setting loop volume to ${volume}`);
      currentVolume.current = volume;
      
      // Apply volume with a small ramp to avoid clicks
      const now = audioContextRef.current.currentTime;
      gainNodeRef.current.gain.cancelScheduledValues(now);
      gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, now);
      
      // Use a slightly higher gain value for loops to make them more prominent
      const adjustedVolume = Math.min(volume * 1.2, 1.0); // Boost by 20% but cap at 1.0
      gainNodeRef.current.gain.linearRampToValueAtTime(adjustedVolume, now + 0.05);
      
      console.log(`Adjusted loop volume to ${adjustedVolume}`);
    }
  }, []);
  
  return {
    isPlaying,
    progress,
    loopDuration,
    audioReady,
    initialized,
    togglePlay,
    setGenre,
    initializeAudio,
    setVolume
  };
}; 