import React from 'react';
import './VoicingControls.css';

interface VoicingControlsProps {
  activeVoicing: string;
  pressedKeys: Set<string>;
}

const VoicingControls: React.FC<VoicingControlsProps> = ({ activeVoicing, pressedKeys }) => {
  const voicingTypes = [
    { id: 'open', key: 'w', label: 'open' },
    { id: 'dynamic', key: 'e', label: 'dyn' },
    { id: 'drop2', key: 's', label: 'd2' },
    { id: 'drop3', key: 'd', label: 'd3' },
    { id: 'shell', key: 'x', label: 'up' },
    { id: 'cluster', key: 'c', label: 'ten' },
  ];

  return (
    <div className="voicing-controls">
      {voicingTypes.map(type => (
        <div 
          key={type.id}
          className={`voicingcontrol-button ${activeVoicing === type.id ? 'active' : ''}`}
        >
          <div className='numeral'>{type.label}</div>
          <div className='key-letter'>{type.key.toUpperCase()}</div>
        </div>
      ))}
    </div>
  );
};

export default VoicingControls;