/**
 * Detects if hardware acceleration is likely disabled in any Chrome browser
 * @param {boolean} forceDetection Force detection to return true for testing
 * @returns {Promise<boolean>} True if hardware acceleration appears to be disabled
 */
export const detectHardwareAcceleration = (forceDetection = false) => {
  return new Promise(resolve => {
    // If forcing detection for testing
    if (forceDetection) {
      console.log("Forcing hardware acceleration detection for testing");
      resolve(true);
      return;
    }
    
    // Higher threshold to reduce false positives - based on the user's INP of 976ms
    // We want to only show the notification for truly disabled hardware acceleration
    const DETECTION_THRESHOLD = 100; // Increased from 1.5ms to 4.0ms
    
    // Check if running in Chrome
    const isChrome = navigator.userAgent.indexOf('Chrome') !== -1 && 
                     navigator.userAgent.indexOf('Edge') === -1 &&
                     navigator.userAgent.indexOf('Edg') === -1;
    
    console.log("Checking hardware acceleration in browser");
    console.log("User agent:", navigator.userAgent);
    console.log("Is Chrome:", isChrome);
    
    // Method 1: Canvas test
    const canvas = document.createElement('canvas');
    let gl;
    
    try {
      gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    } catch (e) {
      console.log("WebGL context creation failed:", e);
      resolve(true); // Hardware acceleration is likely off
      return;
    }
    
    if (!gl) {
      console.log("WebGL not available, hardware acceleration is likely off");
      resolve(true);
      return;
    }
    
    // Method 2: Check for renderer
    let renderer = "unknown";
    try {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo) {
        renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || "unknown";
        console.log("WebGL renderer:", renderer);
        
        // Check for software renderers in any browser
        if (renderer.indexOf('SwiftShader') !== -1 || 
            renderer.indexOf('Software') !== -1 || 
            renderer.indexOf('llvmpipe') !== -1 ||
            renderer.toLowerCase().indexOf('software') !== -1) {
          console.log("Software renderer detected");
          resolve(true);
          return;
        }
        
        // Don't flag ANGLE as a problem unless it's definitely a software renderer
        if (renderer.indexOf('ANGLE') !== -1 && 
            (renderer.indexOf('Software') !== -1 || renderer.indexOf('SwiftShader') !== -1)) {
          console.log("Software ANGLE renderer detected");
          resolve(true);
          return;
        }
      }
    } catch (e) {
      console.log("Error getting renderer info:", e);
    }
    
    // Method 3: Performance test (effective across all browsers)
    let start = performance.now();
    
    // Create small animation to test
    let testDiv = document.createElement('div');
    testDiv.style.position = 'absolute';
    testDiv.style.left = '-9999px';
    testDiv.style.top = '-9999px';
    testDiv.style.width = '50px';
    testDiv.style.height = '50px';
    testDiv.style.backgroundColor = 'red';
    document.body.appendChild(testDiv);
    
    let frames = 0;
    const testFrames = 10; // Reduced frame count for faster testing
    
    const runFrame = () => {
      testDiv.style.transform = `translateX(${frames % 10}px) translateZ(0)`;
      frames++;
      
      if (frames < testFrames) {
        requestAnimationFrame(runFrame);
      } else {
        const end = performance.now();
        const duration = end - start;
        
        // Clean up
        document.body.removeChild(testDiv);
        
        // If average frame time is > DETECTION_THRESHOLD ms on a simple operation, likely hardware acceleration is off
        const avgFrameTime = duration / testFrames;
        console.log("Average frame time:", avgFrameTime, "ms");
        
        resolve(avgFrameTime > DETECTION_THRESHOLD);
      }
    };
    
    requestAnimationFrame(runFrame);
  });
};

/**
 * Shows a notification if hardware acceleration is disabled
 * @param {boolean} forceShow Force show the notification for testing
 */
export const showHardwareAccelerationWarning = async (forceShow = false) => {
  console.log("Checking hardware acceleration...");
  const isDisabled = forceShow || await detectHardwareAcceleration(forceShow);
  console.log("Hardware acceleration disabled:", isDisabled);
  
  if (isDisabled) {
    console.log("Creating notification...");
    const notification = document.createElement('div');
    notification.style.position = 'fixed';
    notification.style.bottom = '20px';
    notification.style.right = '20px';
    notification.style.backgroundColor = '#010000'; // Brighter red color
    notification.style.color = 'white';
    notification.style.padding = '15px 20px';
    notification.style.borderRadius = '5px';
    notification.style.zIndex = '99999'; // Higher z-index to ensure visibility
    notification.style.maxWidth = '350px';
    notification.style.fontSize = '14px';
    notification.style.fontWeight = '500'; // Make text bolder
    notification.style.lineHeight = '1.5'; // Improve readability
    notification.style.animation = 'hwBounceIn 0.5s ease-out'; // Add animation
    
    // Add animation keyframes
    const style = document.createElement('style');
    style.textContent = `
      @keyframes hwBounceIn {
        0% { transform: translateY(100px); opacity: 0; }
        70% { transform: translateY(-10px); }
        100% { transform: translateY(0); opacity: 1; }
      }
    `;
    document.head.appendChild(style);
    
    // Detect browser type for specific instructions
    const isChrome = navigator.userAgent.indexOf('Chrome') !== -1 && 
                    navigator.userAgent.indexOf('Edge') === -1 &&
                    navigator.userAgent.indexOf('Edg') === -1;
    const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && 
                    navigator.userAgent.indexOf('Chrome') === -1;
    
    let browserInstructions = '';
    
    if (isChrome) {
      browserInstructions = 'Settings → System → Use hardware acceleration when available';
    } else if (isFirefox) {
      browserInstructions = 'Settings → Performance → Use recommended performance settings';
    } else if (isSafari) {
      browserInstructions = 'Preferences → Advanced → Show Develop menu → Develop → Disable GPU Process';
    } else {
      browserInstructions = 'browser settings to enable hardware acceleration';
    }
    
    notification.innerHTML = `
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <div>
          <p style="display: block; margin-bottom: 5px; font-size: 16px; color: #E8E8E8; opacity:1;">⚠️ Performance Issue Detected</p>
          <p style="margin: 0; color: #E8E8E8; opacity:0.75; font-weight: 400;">For better performance, enable hardware acceleration in your browser:</p>
          <p style="margin: 5px 0 0; color: #E8E8E8; opacity:0.75; font-weight: 400;">${browserInstructions}</p>
        </div>
        <div style="margin-left: 15px; cursor: pointer; font-size: 18px;" id="close-hw-notice">✕</div>
      </div>
    `;
    
    document.body.appendChild(notification);
    console.log("Notification added to DOM");
    
    document.getElementById('close-hw-notice').addEventListener('click', () => {
      document.body.removeChild(notification);
      localStorage.setItem('hwAccelWarningDismissed', Date.now().toString());
    });
    
    return true;
  }
  
  return false;
};

/**
 * Check if hardware acceleration is disabled and show warning if necessary
 * Will only show once per week (controlled by localStorage)
 * @param {boolean} forceCheck Force check and show notification for testing
 */
export const checkHardwareAcceleration = (forceCheck = false) => {
  console.log("checkHardwareAcceleration called, forceCheck:", forceCheck);
  
  // REMOVED: We no longer clear dismissals for every check
  // localStorage.removeItem('hwAccelWarningDismissed');
  
  // If forcing check, skip the localStorage check
  if (!forceCheck) {
    const lastDismissed = localStorage.getItem('hwAccelWarningDismissed');
    
    // Only show once per week
    if (lastDismissed) {
      const oneWeekAgo = Date.now() - (7 * 24 * 60 * 60 * 1000);
      if (parseInt(lastDismissed) > oneWeekAgo) {
        console.log("Notification was dismissed recently, not showing again");
        return;
      }
    }
  } else {
    // Clear for testing
    localStorage.removeItem('hwAccelWarningDismissed');
  }
  
  // Wait until page is loaded
  if (document.readyState === 'complete') {
    console.log("Document already complete, showing warning");
    showHardwareAccelerationWarning(forceCheck);
  } else {
    console.log("Document not complete, waiting for load event");
    window.addEventListener('load', () => {
      // Delay the check slightly to not impact initial load performance
      console.log("Document loaded, showing warning after delay");
      setTimeout(() => showHardwareAccelerationWarning(forceCheck), 1000);
    });
  }
}; 