// Define all scales with proper type annotation
export const SCALES: Record<string, string[]> = {
  'C Major': ['C', 'D', 'E', 'F', 'G', 'A', 'B'],
  'C Minor': ['C', 'D', 'Eb', 'F', 'G', 'Ab', 'Bb'],
  'C# Major': ['C#', 'D#', 'F', 'F#', 'G#', 'A#', 'C'],
  'C# Minor': ['C#', 'D#', 'E', 'F#', 'G#', 'A', 'B'],
  'Db Major': ['Db', 'Eb', 'F', 'Gb', 'Ab', 'Bb', 'C'],
  'D Major': ['D', 'E', 'F#', 'G', 'A', 'B', 'C#'],
  'D Minor': ['D', 'E', 'F', 'G', 'A', 'Bb', 'C'],
  'Eb Major': ['Eb', 'F', 'G', 'Ab', 'Bb', 'C', 'D'],
  'D# Minor': ['D#', 'E#', 'F#', 'G#', 'A#', 'B', 'C#'],
  'Eb Minor': ['Eb', 'F', 'Gb', 'Ab', 'Bb', 'B', 'Db'],
  'E Major': ['E', 'F#', 'G#', 'A', 'B', 'C#', 'D#'],
  'E Minor': ['E', 'F#', 'G', 'A', 'B', 'C', 'D'],
  'F Major': ['F', 'G', 'A', 'Bb', 'C', 'D', 'E'],
  'F Minor': ['F', 'G', 'Ab', 'Bb', 'C', 'Db', 'Eb'],
  'F# Major': ['F#', 'G#', 'A#', 'B', 'C#', 'D#', 'F'],
  'F# Minor': ['F#', 'G#', 'A', 'B', 'C#', 'D', 'E'],
  'Gb Major': ['Gb', 'Ab', 'Bb', 'B', 'Db', 'Eb', 'F'],
  'G Major': ['G', 'A', 'B', 'C', 'D', 'E', 'F#'],
  'G Minor': ['G', 'A', 'Bb', 'C', 'D', 'Eb', 'F'],
  'Ab Major': ['Ab', 'Bb', 'C', 'Db', 'Eb', 'F', 'G'],
  'G# Minor': ['G#', 'A#', 'B', 'C#', 'D#', 'E', 'F#'],
  'Ab Minor': ['Ab', 'Bb', 'B', 'Db', 'Eb', 'E', 'Gb'],
  'A Major': ['A', 'B', 'C#', 'D', 'E', 'F#', 'G#'],
  'A Minor': ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
  'Bb Major': ['Bb', 'C', 'D', 'Eb', 'F', 'G', 'A'],
  'A# Minor': ['A#', 'B#', 'C#', 'D#', 'E#', 'F#', 'G#'],
  'Bb Minor': ['Bb', 'C', 'Db', 'Eb', 'F', 'Gb', 'Ab'],
  'B Major': ['B', 'C#', 'D#', 'E', 'F#', 'G#', 'A#'],
  'B Minor': ['B', 'C#', 'D', 'E', 'F#', 'G', 'A'],
};

// Define chord types with proper type annotation
const CHORD_TYPES: Record<string, Record<string, number[]>> = {
  'major': {
    'triad': [0, 4, 7], // Root, major 3rd, perfect 5th
    '7th': [0, 4, 7, 11], // Root, major 3rd, perfect 5th, major 7th
    '9th': [0, 4, 7, 11, 14], // Root, major 3rd, perfect 5th, major 7th, 9th
    '13th': [0, 4, 7, 11, 14, 17, 21], // Root, major 3rd, perfect 5th, major 7th, 9th, 13th
    'sus4': [0, 5, 7], // Root, 4th, 5th
    'sus2': [0, 2, 7], // Root, 2nd, 5th
    'dom7': [0, 4, 7, 10] // Root, major 3rd, perfect 5th, minor 7th
  },
  'minor': {
    'triad': [0, 3, 7], // Root, minor 3rd, perfect 5th
    '7th': [0, 3, 7, 10], // Root, minor 3rd, perfect 5th, minor 7th
    '9th': [0, 3, 7, 10, 14], // Root, minor 3rd, perfect 5th, minor 7th, 9th
    '13th': [0, 3, 7, 10, 14, 17, 21], // Root, minor 3rd, perfect 5th, minor 7th, 9th, 13th
    'sus4': [0, 5, 7], // Root, 4th, 5th
    'sus2': [0, 2, 7], // Root, 2nd, 5th
    'dom7': [0, 3, 7, 10] // Root, minor 3rd, perfect 5th, minor 7th
  },
  'diminished': {
    'triad': [0, 3, 6], // Root, minor 3rd, diminished 5th
    '7th': [0, 3, 6, 9], // Root, minor 3rd, diminished 5th, diminished 7th
    '9th': [0, 3, 6, 9, 12], // Root, minor 3rd, diminished 5th, diminished 7th, minor 9th
    '13th': [0, 3, 6, 9, 12, 15, 18], // Root, minor 3rd, diminished 5th, diminished 7th, minor 9th, minor 13th
    'sus4': [0, 5, 6], // Root, 4th, diminished 5th
    'sus2': [0, 2, 6], // Root, 2nd, diminished 5th
    'dom7': [0, 3, 6, 9] // Root, minor 3rd, diminished 5th, diminished 7th
  }
};

// Define genre-specific transition types
export const GENRE_TRANSITION_TYPES: Record<string, string> = {
  'pop': 'sus4',
  'rock': '5th',
  '80sdisco': '7th',
  'hiphop': 'dim',
  'trap': 'dim',
  'rnb': '9th',
  'funk': '9th',
  'jazz': '9th',
  'edm': 'sus4',
  'citypop': '7th',
  // Default fallback
  'default': 'sus4'
};

// Define genre-specific transition chord types
export const GENRE_TRANSITION_CHORD_TYPES: Record<string, string> = {
  'pop': 'sus4',
  'rock': 'dom7',
  'hip hop': 'dim',
  'soul': 'sus4',
  'rnb': 'dom7',
  'jazz': 'dom7',
  'city pop': 'dom7',
  '80s disco': 'sus4',
  'funk': '9',
  'trap': 'sus2'
};

// Define relative octave shifts for each degree in each scale
// This maps scale names to arrays of octave shifts for each degree (1-indexed)
// A value of 0 means no shift, 1 means shift up one octave, -1 means shift down one octave
const SCALE_DEGREE_OCTAVE_SHIFTS: Record<string, number[]> = {
  // Major scales
  'C Major':  [0, 0, 0, 0, 0, 0, 0], // No shifts needed
  'G Major':  [0, 0, 0, 1, 1, 1, 1], // iv-vii shift down
  'D Major':  [0, 0, 0, 0, 0, 0, 1], // vii shifts down
  'A Major':  [0, 0, 1, 1, 1, 1, 1], // iv-vii shift down
  'E Major':  [0, 0, 0, 0, 0, 1, 1], // No shifts needed
  'B Major':  [0, 1, 1, 1, 1, 1, 1], // No shifts needed
  'F# Major': [0, 0, 0, 0, 1, 1, 1], // No shifts needed
  'Gb Major': [0, 0, 0, 0, 0, 0, 0], // No shifts needed
  'C# Major': [0, 0, 0, 0, 0, 0, 1], // No shifts needed
  'Ab Major': [0, 0, 1, 1, 1, 1, 1], // No shifts needed
  'Eb Major': [0, 0, 0, 0, 0, 1, 1], // No shifts needed
  'Bb Major': [0, 1, 1, 1, 1, 1, 1], // iv-vii shift up
  'F Major':  [0, 0, 0, 0, 1, 1, 1], // v-vii shift up
  
  // Minor scales
  'C Minor':  [0, 0, 0, 0, 0, 0, 0], // No shifts needed
  'G Minor':  [0, 0, 0, 1, 1, 1, 1], // iv-vii shift down
  'D Minor':  [0, 0, 0, 0, 0, 0, 1], // No shifts needed
  'A Minor':  [0, 0, 1, 1, 1, 1, 1], // iv-vii shift down
  'E Minor':  [0, 0, 0, 0, 0, 1, 1], // No shifts needed
  'B Minor':  [0, 1, 1, 1, 1, 1, 1], // No shifts needed
  'F# Minor': [0, 0, 0, 0, 1, 1, 1], // No shifts needed
  'C# Minor': [0, 0, 0, 0, 0, 0, 0], // No shifts needed
  'Ab Minor': [0, 0, 0, 1, 1, 1, 1], // No shifts needed
  'Eb Minor': [0, 0, 0, 0, 0, 0, 1], // No shifts needed
  'Bb Minor': [0, 1, 1, 1, 1, 1, 1], // iv-vii shift up
  'F Minor':  [0, 0, 0, 0, 1, 1, 1], // v-vii shift up
  
  // Add any other scales as needed
};

// Default octave shifts if scale is not defined
const DEFAULT_OCTAVE_SHIFTS = [0, 0, 0, 0, 0, 0, 0];

// Define precise voicing patterns for each chord type and voicing style
// The numbers represent octave offsets from the base octave
const VOICING_PATTERNS: Record<string, Record<string, number[][]>> = {
  'closed': {
    'triad': [[0, 0, 0]],
    '7th': [[0, 0, 0, 0]],
    '9th': [[0, 0, 0, 0, 0]],
    '13th': [[0, 0, 0, 0, 0, 0, 1]],
    'dim': [[0, 0, 0]],
    'sus4': [[0, 0, 0]],
    'sus2': [[0, 0, 0]],
    'dom7': [[0, 0, 0, 0]]
  },
  'open': {
    'triad': [[0, 1, 0]],
    '7th': [[0, 1, 0, 1]],
    '9th': [[0, 1, 0, 1, 1]],
    '13th': [[0, 1, 0, 1, 2, 1, 2]],
    'dim': [[0, 1, 0]],
    'sus4': [[0, 1, 0]],
    'sus2': [[0, 1, 0]],
    'dom7': [[0, 1, 0, 1]]
  },
  'spread': {
    'triad': [[0, 1, 2]],
    '7th': [[0, 1, 2, 1]],
    '9th': [[0, 1, 2, 1, 2]],
    '13th': [[0, 1, 2, 1, 2, 3, 3]],
    'dim': [[0, 1, 2]],
    'sus4': [[0, 1, 2]],
    'sus2': [[0, 1, 2]],
    'dom7': [[0, 1, 2, 1]]
  },
  'drop2': {
    'triad': [[0, 0, -1]],
    '7th': [[0, 0, -1, 0]],
    '9th': [[0, 0, -1, 0, 0]],
    '13th': [[0, 0, -1, 0, 0, 0, 1]],
    'dim': [[0, 0, -1]],
    'sus4': [[0, 0, -1]],
    'sus2': [[0, 0, -1]],
    'dom7': [[0, 0, -1, 0]]
  },
  'drop3': {
    'triad': [[0, -1, -1]],
    '7th': [[0, -1, 0, 0]],
    '9th': [[0, -1, 0, 0, 0]],
    '13th': [[0, -1, 0, 0, 0, 0, 1]],
    'dim': [[0, -1, -1]],
    'sus4': [[0, -1, -1]],
    'sus2': [[0, -1, -1]],
    'dom7': [[0, -1, 0, 0]]
  },
  'shell': {
    'triad': [[1, 0, 0]],       // Root, 3rd, 5th with 5th up an octave
    '7th': [[1, 0, 0, 1]],    // Root, 3rd, 5th, 7th with 5th and 7th down an octave
    '9th': [[1, 0, 1, 1, 1]], // Root, 3rd, 5th, 7th, 9th with 5th and 7th down, 9th up
    '13th': [[1, 0, 1, 1, 2, 1, 2]], // Root, 3rd, 5th, 7th, 9th, 13th
    'dim': [[1, 0, 0]],        // Root, b3, dim5 with dim5 down an octave
    'sus4': [[1, 0, 0]],        // Root, 4th, 5th with 5th up an octave
    'sus2': [[1, 0, 0]],        // Root, 2nd, 5th with 5th up an octave
    'dom7': [[1, 0, 0, 1]]    // Root, 3rd, 5th, b7 with 5th and b7 down an octave
  },
  'cluster': {
    'triad': [[0, 0, 0]],      // Root, 3rd, 5th with 5th down an octave
    '7th': [[1, 0, 0, 0]],      // Root up octave, 3rd, 5th, 7th in base octave
    '9th': [[1, 0, 0, 0, 0]],   // Root up octave, rest in base octave
    '13th': [[1, 0, 0, 0, 0, 0, 0]], // Root up octave, rest in base octave
    'dim': [[0, 0, 0]],        // Root, b3, dim5 with dim5 down an octave
    'sus4': [[0, 0, 0]],       // Root, 4th, 5th with 5th down an octave
    'sus2': [[0, 0, 0]],       // Root, 2nd, 5th with 5th down an octave
    'dom7': [[1, 0, 0, 0]]      // Root up octave, 3rd, 5th, b7 in base octave
  },
  // Adding placeholder for dynamic voicing - actual logic is in dynamicVoicing function
  'dynamic': {
    'triad': [[0, 0, 0]],      // Placeholder, actual logic in dynamicVoicing
    '7th': [[0, 0, 0, 0]],     // Placeholder, actual logic in dynamicVoicing
    '9th': [[0, 0, 0, 0, 0]],  // Placeholder, actual logic in dynamicVoicing
    '13th': [[0, 0, 0, 0, 0, 0, 0]], // Placeholder, actual logic in dynamicVoicing
    'dim': [[0, 0, 0]],        // Placeholder, actual logic in dynamicVoicing
    'sus4': [[0, 0, 0]],       // Placeholder, actual logic in dynamicVoicing
    'sus2': [[0, 0, 0]],       // Placeholder, actual logic in dynamicVoicing
    'dom7': [[0, 0, 0, 0]]     // Placeholder, actual logic in dynamicVoicing
  }
};

// Define note orders for each voicing type to match the test cases
const NOTE_ORDERS: Record<string, Record<string, number[]>> = {
  'closed': {
    'triad': [0, 1, 2],
    '7th': [0, 1, 2, 3],
    '9th': [0, 1, 2, 3, 4],
    '13th': [0, 1, 2, 3, 4, 5, 6],
    'dim': [0, 1, 2],
    'sus4': [0, 1, 2],
    'sus2': [0, 1, 2],
    'dom7': [0, 1, 2, 3]
  },
  'open': {
    'triad': [0, 2, 1],
    '7th': [0, 2, 1, 3],
    '9th': [0, 2, 1, 3, 4],
    '13th': [0, 2, 1, 3, 5, 6, 4],
    'dim': [0, 2, 1],
    'sus4': [0, 2, 1],
    'sus2': [0, 2, 1],
    'dom7': [0, 2, 3, 1]
  },
  'spread': {
    'triad': [0, 2, 1],
    '7th': [0, 1, 3, 2],
    '9th': [0, 1, 3, 2, 4],
    '13th': [0, 1, 3, 2, 4, 6, 5],
    'dim': [0, 2, 1],
    'sus4': [0, 2, 1],
    'sus2': [0, 2, 1],
    'dom7': [0, 1, 3, 2]
  },
  'drop2': {
    'triad': [2, 0, 1],
    '7th': [2, 0, 1, 3],
    '9th': [2, 0, 1, 3, 4],
    '13th': [2, 0, 1, 3, 4, 5, 6],
    'dim': [2, 0, 1],
    'sus4': [2, 0, 1],
    'sus2': [2, 0, 1],
    'dom7': [2, 0, 1, 3]
  },
  'drop3': {
    'triad': [2, 1, 0],
    '7th': [1, 0, 2, 3],
    '9th': [1, 0, 2, 3, 4],
    '13th': [1, 0, 2, 3, 4, 5, 6],
    'dim': [2, 1, 0],
    'sus4': [2, 1, 0],
    'sus2': [2, 1, 0],
    'dom7': [1, 0, 2, 3]
  },
  'shell': {
    'triad': [1, 2, 0],         // Root, 3rd, 5th
    '7th': [1, 2, 0, 3],        // Root, 3rd, 7th, 5th
    '9th': [1, 0, 2, 3, 4],     // Root, 3rd, 7th, 5th, 9th
    '13th': [1, 0, 2, 3, 5, 6, 4], // Root, 3rd, 7th, 5th, 9th, 13th
    'dim': [1, 2, 0],           // Root, b3, dim5
    'sus4': [1, 2, 0],          // Root, 4th, 5th
    'sus2': [1, 2, 0],          // Root, 2nd, 5th
    'dom7': [1, 2, 0, 3]        // Root, 3rd, b7, 5th
  },
  'cluster': {
    'triad': [0, 1, 2],         // 5th, Root, 3rd
    '7th': [1, 2, 3, 0],        // Root, 3rd, 5th, 7th
    '9th': [1, 2, 3, 0, 4],     // Root, 9th, 3rd, 5th, 7th
    '13th': [1, 2, 3, 6, 0, 4, 5], // Root, 13th, 3rd, 5th, 7th, 9th
    'dim': [0, 1, 2],           // dim5, Root, b3
    'sus4': [0, 1, 2],          // 5th, Root, 4th
    'sus2': [0, 1, 2],          // 5th, Root, 2nd
    'dom7': [1, 2, 3, 0]        // Root, 3rd, 5th, b7
  },
  // Adding placeholder for dynamic voicing - actual sorting happens in dynamicVoicing function
  'dynamic': {
    'triad': [0, 1, 2],         // Placeholder, actual logic in dynamicVoicing
    '7th': [0, 1, 2, 3],        // Placeholder, actual logic in dynamicVoicing
    '9th': [0, 1, 2, 3, 4],     // Placeholder, actual logic in dynamicVoicing
    '13th': [0, 1, 2, 3, 4, 5, 6], // Placeholder, actual logic in dynamicVoicing
    'dim': [0, 1, 2],           // Placeholder, actual logic in dynamicVoicing
    'sus4': [0, 1, 2],          // Placeholder, actual logic in dynamicVoicing
    'sus2': [0, 1, 2],          // Placeholder, actual logic in dynamicVoicing
    'dom7': [0, 1, 2, 3]        // Placeholder, actual logic in dynamicVoicing
  }
};

// Define genre-specific chord progressions
const GENRE_PROGRESSIONS: Record<string, Record<number, number[]>> = {
  'rock': {
    1: [4, 5, 6], // I -> IV, V, vi
    2: [5, 7],    // ii -> V, vii
    3: [4, 6],    // iii -> IV, vi
    4: [1, 5],    // IV -> I, V
    5: [1, 4, 6], // V -> I, IV, vi
    6: [2, 4, 5], // vi -> ii, IV, V
    7: [1, 3]     // vii -> I, iii
  },
  'pop': {
    1: [4, 5, 6], // I -> IV, V, vi
    2: [5, 1],    // ii -> V, I
    3: [6, 4],    // iii -> vi, IV
    4: [1, 5, 6], // IV -> I, V, vi
    5: [1, 6, 4], // V -> I, vi, IV
    6: [4, 2, 5], // vi -> IV, ii, V
    7: [1]        // vii -> I
  },
  'hip hop': {
    1: [4, 6, 5], // I -> IV, vi, V
    2: [5, 1],    // ii -> V, I
    3: [6],       // iii -> vi
    4: [5, 1, 6], // IV -> V, I, vi
    5: [1, 6, 4], // V -> I, vi, IV
    6: [4, 5, 2], // vi -> IV, V, ii
    7: [1]        // vii -> I
  },
  'soul': {
    1: [4, 6, 3], // I -> IV, vi, iii
    2: [5, 1],    // ii -> V, I
    3: [6, 2],    // iii -> vi, ii
    4: [5, 1, 2], // IV -> V, I, ii
    5: [1, 6, 4], // V -> I, vi, IV
    6: [2, 5, 4], // vi -> ii, V, IV
    7: [3, 1]     // vii -> iii, I
  },
  'rnb': {
    1: [6, 4, 5], // I -> vi, IV, V
    2: [5, 1, 7], // ii -> V, I, vii
    3: [6, 2],    // iii -> vi, ii
    4: [5, 2, 1], // IV -> V, ii, I
    5: [1, 6, 3], // V -> I, vi, iii
    6: [4, 2, 5], // vi -> IV, ii, V
    7: [3, 1]     // vii -> iii, I
  },
  'blues': {
    1: [4, 5],    // I -> IV, V
    2: [5],       // ii -> V
    3: [6],       // iii -> vi
    4: [1, 5],    // IV -> I, V
    5: [1, 4],    // V -> I, IV
    6: [2, 4],    // vi -> ii, IV
    7: [3, 1]     // vii -> iii, I
  },
  'jazz': {
    1: [6, 2, 5], // I -> vi, ii, V
    2: [5, 1, 7], // ii -> V, I, vii
    3: [6, 2],    // iii -> vi, ii
    4: [7, 3, 6], // IV -> vii, iii, vi
    5: [1, 6, 2], // V -> I, vi, ii
    6: [2, 5, 1], // vi -> ii, V, I
    7: [3, 6, 2]  // vii -> iii, vi, ii
  },
  'city pop': {
    1: [6, 3, 4], // I -> vi, iii, IV
    2: [5, 1],    // ii -> V, I
    3: [6, 4],    // iii -> vi, IV
    4: [5, 2, 1], // IV -> V, ii, I
    5: [1, 6, 3], // V -> I, vi, iii
    6: [2, 5, 4], // vi -> ii, V, IV
    7: [1, 3]     // vii -> I, iii
  },
  'progressive': {
    1: [5, 7, 3], // I -> V, vii, iii
    2: [7, 4, 1], // ii -> vii, IV, I
    3: [6, 7, 4], // iii -> vi, vii, IV
    4: [7, 5, 2], // IV -> vii, V, ii
    5: [6, 3, 1], // V -> vi, iii, I
    6: [7, 4, 2], // vi -> vii, IV, ii
    7: [5, 3, 1]  // vii -> V, iii, I
  }
};

// Define transition chord recommendations
// This maps from [startDegree, endDegree] to potential transition degrees
const TRANSITION_CHORDS: Record<string, Record<string, number[]>> = {
  'rock': {
    '1-4': [6],      // I -> IV: try vi as transition
    '1-5': [4],      // I -> V: try IV as transition
    '1-6': [3],      // I -> vi: try iii as transition
    '4-1': [5],      // IV -> I: try V as transition
    '4-5': [2],      // IV -> V: try ii as transition
    '5-1': [6, 4],   // V -> I: try vi or IV as transition
    '5-6': [1],      // V -> vi: try I as transition
    '6-4': [2, 5],   // vi -> IV: try ii or V as transition
    '6-5': [4],      // vi -> V: try IV as transition
    '2-5': [4],      // ii -> V: try IV as transition
    '3-6': [5],      // iii -> vi: try V as transition
  },
  'pop': {
    '1-4': [6, 3],   // I -> IV: try vi or iii as transition
    '1-5': [4],      // I -> V: try IV as transition
    '1-6': [3, 5],   // I -> vi: try iii or V as transition
    '4-1': [5],      // IV -> I: try V as transition
    '4-5': [2],      // IV -> V: try ii as transition
    '5-1': [6],      // V -> I: try vi as transition
    '5-6': [1, 4],   // V -> vi: try I or IV as transition
    '6-4': [2, 5],   // vi -> IV: try ii or V as transition
    '6-1': [4, 5],   // vi -> I: try IV or V as transition
    '2-5': [4],      // ii -> V: try IV as transition
    '3-6': [4],      // iii -> vi: try IV as transition
  },
  'jazz': {
    '1-6': [3, 7],   // I -> vi: try iii or vii as transition
    '1-2': [6, 3],   // I -> ii: try vi or iii as transition
    '2-5': [7, 4],   // ii -> V: try vii or IV as transition
    '5-1': [2, 6],   // V -> I: try ii or vi as transition
    '6-2': [3, 4],   // vi -> ii: try iii or IV as transition
    '3-6': [7],      // iii -> vi: try vii as transition
    '4-7': [5, 2],   // IV -> vii: try V or ii as transition
    '7-3': [6, 4],   // vii -> iii: try vi or IV as transition
  },
  'blues': {
    '1-4': [5],      // I -> IV: try V as transition
    '4-1': [5],      // IV -> I: try V as transition
    '5-1': [4],      // V -> I: try IV as transition
    '5-4': [1],      // V -> IV: try I as transition
  },
  'soul': {
    '1-4': [3, 6],   // I -> IV: try iii or vi as transition
    '1-6': [3],      // I -> vi: try iii as transition
    '4-1': [5, 2],   // IV -> I: try V or ii as transition
    '5-1': [6, 4],   // V -> I: try vi or IV as transition
    '6-4': [2, 3],   // vi -> IV: try ii or iii as transition
    '2-5': [4],      // ii -> V: try IV as transition
  }
};

// Default transitions for any genre not specifically defined
const DEFAULT_TRANSITIONS: Record<string, number[]> = {
  '1-4': [6, 3],     // I -> IV: try vi or iii
  '1-5': [4],        // I -> V: try IV
  '1-6': [3],        // I -> vi: try iii
  '4-1': [5],        // IV -> I: try V
  '4-5': [2],        // IV -> V: try ii
  '5-1': [6, 4],     // V -> I: try vi or IV
  '5-6': [1],        // V -> vi: try I
  '6-4': [2, 5],     // vi -> IV: try ii or V
  '6-1': [4, 5],     // vi -> I: try IV or V
  '2-5': [4],        // ii -> V: try IV
  '3-6': [5],        // iii -> vi: try V
};

// Map of enharmonic equivalents for display purposes
const ENHARMONIC_DISPLAY_MAP: Record<string, string> = {
  'Cb': 'B',
  'B#': 'C',
  'E#': 'F',
  'Fb': 'E'
};

// Function to get the proper display name for a note
export const getDisplayNoteName = (note: string): string => {
  return ENHARMONIC_DISPLAY_MAP[note] || note;
};

// Function to get the proper display name for a chord
export const getDisplayChordName = (chordName: string): string => {
  // Extract the root note and chord type
  const match = chordName.match(/^([A-G][#b]?)(.*)$/);
  if (!match) return chordName;
  
  const [_, rootNote, chordType] = match;
  
  // Replace problematic root notes with their enharmonic equivalents
  const displayRootNote = ENHARMONIC_DISPLAY_MAP[rootNote] || rootNote;
  
  return `${displayRootNote}${chordType}`;
};

// Helper function to split note and octave
function splitNoteAndOctave(noteWithOctave: string): [string, string] {
  const match = noteWithOctave.match(/([A-G][#b]?)(\d)/);
  if (!match) {
    return ['C', '3']; // Default fallback
  }
  return [match[1], match[2]];
}

// Get chord notes based on scale, degree, chord type and modal setting
function getChordNotes(scaleNotes: string[], degree: number, chordType: string, isModalActive: boolean, scaleName: string = ''): string[] {
  // Adjust for 0-indexed array (degree is 1-indexed in UI)
  const adjustedDegree = degree - 1;
  
  // Get the root note of the chord
  const rootNote = scaleNotes[adjustedDegree % scaleNotes.length];
  
  // Extract if it's a major or minor scale
  const isMajorScale = !scaleName.toLowerCase().includes('minor');
  
  // Determine if this chord would normally be major in the current scale
  let isNormallyMajor = false;
  if (isMajorScale) {
    // In major scale: I, IV, V are major (degrees 1, 4, 5)
    isNormallyMajor = (degree === 1 || degree === 4 || degree === 5);
  } else {
    // In minor scale: III, VI, VII are major (degrees 3, 6, 7)
    isNormallyMajor = (degree === 3 || degree === 6 || degree === 7);
  }
  
  // If modal interchange is active, flip the chord quality
  const isMajor = isModalActive ? !isNormallyMajor : isNormallyMajor;
  
  // Special handling for diminished chords - modal doesn't apply to diminished chords
  if (chordType === 'dim') {
    // Define correct diminished chord notes for each root
    const diminishedChords: Record<string, string[]> = {
      'C': ['C', 'Eb', 'Gb'],
      'C#': ['C#', 'E', 'G'],
      'Db': ['Db', 'E', 'G'],
      'D': ['D', 'F', 'Ab'],
      'D#': ['D#', 'F#', 'A'],
      'Eb': ['Eb', 'Gb', 'A'],
      'E': ['E', 'G', 'Bb'],
      'F': ['F', 'Ab', 'B'],
      'F#': ['F#', 'A', 'C'],
      'Gb': ['Gb', 'A', 'C'],
      'G': ['G', 'Bb', 'Db'],
      'G#': ['G#', 'B', 'D'],
      'Ab': ['Ab', 'B', 'D'],
      'A': ['A', 'C', 'Eb'],
      'A#': ['A#', 'C#', 'E'],
      'Bb': ['Bb', 'Db', 'E'],
      'B': ['B', 'D', 'F']
    };
    
    // Use predefined notes if available
    if (diminishedChords[rootNote]) {
      return diminishedChords[rootNote];
    }
  }
  
  // For other chord types, build the chord directly based on the root note
  return buildChordFromRoot(rootNote, chordType, isMajor, degree, isMajorScale);
}

// Helper function to build a chord from a root note and chord type
function buildChordFromRoot(rootNote: string, chordType: string, isMajor: boolean, degree: number, isMajorScale: boolean): string[] {
  // Define the chromatic scale
  const chromaticScale = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  
  // Define the flat version of the chromatic scale for proper note naming
  const flatChromaticScale = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
  
  // Determine if we should use flats or sharps based on the root note
  const useFlats = rootNote.includes('b') || 
                  ['F', 'Bb', 'Eb', 'Ab', 'Db', 'Gb'].includes(rootNote);
  
  // Get the position of the root note in the chromatic scale
  let rootIndex = -1;
  if (rootNote.includes('b')) {
    rootIndex = flatChromaticScale.indexOf(rootNote);
  } else if (rootNote.includes('#')) {
    rootIndex = chromaticScale.indexOf(rootNote);
  } else {
    rootIndex = chromaticScale.indexOf(rootNote);
  }
  
  if (rootIndex === -1) {
    return [rootNote]; // Return just the root note as fallback
  }

  // Determine if this is a diminished chord based on scale degree
  const isDiminished = (isMajorScale && degree === 7) || (!isMajorScale && degree === 2);
  
  // Special case for V chord in major scale or VII chord in minor scale - use dominant intervals
  const isDominantChord = (isMajorScale && degree === 5) || (!isMajorScale && degree === 7);
  
  // Get the intervals based on chord quality and type
  const quality = isDiminished ? 'diminished' : (isMajor ? 'major' : 'minor');
  let intervals = CHORD_TYPES[quality]?.[chordType];
  
  // Override intervals for dominant chords (V in major or VII in minor)
  // Only apply dominant override when the chord should be major (not when modal is active)
  if (isDominantChord && isMajor) {
    switch (chordType) {
      case '7th':
        intervals = [0, 4, 7, 10]; // Dominant 7th
        break;
      case '9th':
        intervals = [0, 4, 7, 10, 14]; // Dominant 9th
        break;
      case '13th':
        intervals = [0, 4, 7, 10, 14, 21]; // Dominant 13th
        break;
      // For triad, we keep the major intervals [0, 4, 7]
    }
  }
  
  if (!intervals) {
    return [rootNote]; // Return just the root note if chord type not found
  }
  
  // Build the chord by applying each interval
  const chord = intervals.map(interval => {
    // Calculate the note index by adding the interval to the root index
    const noteIndex = (rootIndex + interval) % 12;
    
    // Return the note using the appropriate scale (flat or sharp)
    return useFlats ? flatChromaticScale[noteIndex] : chromaticScale[noteIndex];
  });
  
  return chord;
}

// Add the missing getScaleNotes function
export const getScaleNotes = (keyAndScale: string): string[] => {
  return SCALES[keyAndScale] || SCALES['C Major'];
};

// Add the missing applyModalShift function
const applyModalShift = (intervals: number[]): number[] => {
  // For modal interchange, we typically flatten the 3rd and 7th
  // This is a simplified implementation
  return intervals.map(interval => {
    // Flatten the 3rd (major to minor)
    if (interval === 4) return 3;
    // Flatten the 7th (major to minor)
    if (interval === 11) return 10;
    return interval;
  });
};

// Update the getNotes function to pass the scale name to getChordNotes
export const getNotes = ({ 
  scale, 
  degree, 
  octave, 
  chordType = 'triad', 
  voicing = 'closed',
  isModalActive = false
}: {
  scale: string;
  degree: number;
  octave: number;
  chordType?: string;
  voicing?: string;
  isModalActive?: boolean;
}): string[] => {
  // Prevent modal interchange for diminished chords
  if (isDiminishedChord(scale, degree)) {
    isModalActive = false;
  }

  // Get the scale notes
  const scaleNotes = getScaleNotes(scale);
  
  // Get the root note with the correct octave from our explicit mapping
  const rootNoteWithOctave = getRootNoteWithOctave(scale, degree, octave, voicing === 'dynamic');
  const [rootNote, rootOctave] = splitNoteAndOctave(rootNoteWithOctave);
  
  // Get the chord notes (without octave) - pass the scale name
  const chordNotes = getChordNotes(scaleNotes, degree, chordType, isModalActive, scale);
  
  // Define the chromatic scale for position comparison
  const chromaticScale = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  
  // Normalize notes to handle flats and other enharmonic equivalents
  const normalizeNote = (note: string): string => {
    // Handle special cases like Cb, Fb, E#, B#
    if (note === 'Cb') return 'B';
    if (note === 'Fb') return 'E';
    if (note === 'E#') return 'F';
    if (note === 'B#') return 'C';
    
    // Handle standard flats
    if (note === 'Db') return 'C#';
    if (note === 'Eb') return 'D#';
    if (note === 'Gb') return 'F#';
    if (note === 'Ab') return 'G#';
    if (note === 'Bb') return 'A#';
    
    return note;
  };
  
  // Get the chromatic position of the root note
  const normalizedRoot = normalizeNote(rootNote);
  const rootPosition = chromaticScale.indexOf(normalizedRoot);
  
  // Add octave to each note based on CONSISTENT rules for ALL chords
  const notesWithOctave = chordNotes.map((note, index) => {
    if (index === 0) {
      // This is the root note, use the octave from getRootNoteWithOctave
      // Replace problematic notes with their enharmonic equivalents
      const displayNote = getDisplayNoteName(rootNote);
      return `${displayNote}${rootOctave}`;
    } else {
      // For other notes, determine the appropriate octave
      const normalizedNote = normalizeNote(note);
      const notePosition = chromaticScale.indexOf(normalizedNote);
      
      // Default octave is the same as the root
      let noteOctave = parseInt(rootOctave);
      
      // CONSISTENT RULE: If the note is earlier in the chromatic scale than the root,
      // it should be in the next octave
      if (notePosition < rootPosition) {
        noteOctave += 1;
      }
      
      // Special case for Cb - it's actually B in the previous octave
      if (note === 'Cb') {
        noteOctave -= 1;
        return `B${noteOctave}`;
      }
      
      // Special case for B# - it's actually C in the next octave
      if (note === 'B#') {
        noteOctave += 1;
        return `C${noteOctave}`;
      }
      
      // For extended chords (9th, 13th), put extensions in higher octaves
      if (chordType === '9th' && index === 4) {
        // 9th degree goes up an octave
        noteOctave += 1;
      } else if (chordType === '13th' && (index === 4 || index === 5)) {
        // 9th and 13th degrees go up an octave
        noteOctave += 1;
      }
      
      // Use the display name for the note
      const displayNote = getDisplayNoteName(note);
      return `${displayNote}${noteOctave}`;
    }
  });
  
  // Apply voicing
  const notes = applyVoicing(notesWithOctave, voicing, parseInt(rootOctave), chordType);
  
  return notes;
};

// Helper function to calculate a note given a starting note and semitone interval
function calculateNoteFromInterval(startNote: string, semitones: number): string {
  const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  
  // Find the index of the start note
  let startIndex = notes.indexOf(startNote);
  if (startIndex === -1) {
    // Handle flats by converting to equivalent sharps
    if (startNote === 'Db') startIndex = notes.indexOf('C#');
    else if (startNote === 'Eb') startIndex = notes.indexOf('D#');
    else if (startNote === 'Gb') startIndex = notes.indexOf('F#');
    else if (startNote === 'Ab') startIndex = notes.indexOf('G#');
    else if (startNote === 'Bb') startIndex = notes.indexOf('A#');
    else startIndex = 0; // Default to C if not found
  }
  
  // Calculate the new index
  const newIndex = (startIndex + semitones) % 12;
  
  return notes[newIndex];
}

// Get next likely chords based on genre
export function getNextChords(scale: string, degree: number, genre: string): number[] {
  const genreProgressions = GENRE_PROGRESSIONS[genre] || GENRE_PROGRESSIONS['pop'];
  return genreProgressions[degree] || [1, 4, 5]; // Default to common I-IV-V if no specific progression
}

// Get recommended transition chords between two degrees
export function getTransitionChords(
  fromDegree: number,
  toDegree: number,
  genre: string
): number[] {
  const transitionKey = `${fromDegree}-${toDegree}`;
  
  // Get genre-specific transitions or fall back to defaults
  const genreTransitions = TRANSITION_CHORDS[genre] || DEFAULT_TRANSITIONS;
  
  // Return recommended transitions or empty array if none defined
  return genreTransitions[transitionKey] || [];
}

// Get the chord name for a given scale, degree, and chord type
export const getChordName = (keyAndScale: string, degree: number, chordType: string, isModalActive: boolean = false, isBorrowed: boolean = false): string => {
  if (!keyAndScale) {
    return `Chord${degree}`; // Return a fallback value
  }
  
  // Get the scale notes for the current key and scale
  const scaleNotes = getScaleNotes(keyAndScale);
  
  // Get the root note from the scale notes
  const rootNote = scaleNotes[(degree - 1) % scaleNotes.length];
  
  // For borrowed chords, we want to show the lowered note as the root
  if (isBorrowed) {
    // Special case for C major/minor when root is C
    let loweredRoot: string;
    if (rootNote === 'C') {
      loweredRoot = 'B';
    } else {
      loweredRoot = calculateNoteFromInterval(rootNote, -1);
    }
    
    // Format the chord name based on the chord type
    let chordSuffix = '';
    
    // Check if modal is active - for borrowed chords, modal makes them minor
    const isMinor = isModalActive;
    
    switch (chordType) {
      case 'triad':
        chordSuffix = isMinor ? 'm' : '';
        break;
      case '7th':
        chordSuffix = isMinor ? 'm7' : 'maj7';
        break;
      case '9th':
        chordSuffix = isMinor ? 'm9' : 'maj9';
        break;
      case '13th':
        chordSuffix = isMinor ? 'm13' : 'maj13';
        break;
      case 'sus4':
        chordSuffix = 'sus4'; // sus chords don't change with modal
        break;
      case 'sus2':
        chordSuffix = 'sus2'; // sus chords don't change with modal
        break;
      case 'dom7':
        chordSuffix = isMinor ? 'm7' : '7';
        break;
      default:
        chordSuffix = isMinor ? 'm' + chordType : chordType;
    }
    
    // Special case for C Major scale - use flat notation
    if (keyAndScale === 'C Major' && degree === 6 && loweredRoot === 'G') {
      loweredRoot = 'Ab'; // Use Ab instead of G#
    } else if (keyAndScale === 'C Major' && degree === 7 && loweredRoot === 'A') {
      loweredRoot = 'Bb'; // Use Bb instead of A#
    }
    
    return `${loweredRoot}${chordSuffix}`;
  }
  
  // For non-borrowed chords, continue with the existing logic
  // Determine if this is a major or minor scale
  const isMajorScale = !keyAndScale.toLowerCase().includes('minor');
  
  // Determine if this chord would normally be major or minor in the current scale
  let isNormallyMajor = false;
  if (isMajorScale) {
    // In major scale: I, IV, V are major
    isNormallyMajor = (degree === 1 || degree === 4 || degree === 5);
  } else {
    // In minor scale: III, VI, VII are major
    isNormallyMajor = (degree === 3 || degree === 6 || degree === 7);
  }
  
  // If modal interchange is active, flip the chord quality
  const isMajor = isModalActive ? !isNormallyMajor : isNormallyMajor;
  
  // Determine if it's diminished
  let isDiminished = false;
  if (isMajorScale) {
    // In major scale: vii° is diminished
    isDiminished = (degree === 7);
  } else {
    // In minor scale: ii° is diminished
    isDiminished = (degree === 2);
  }
  
  // Format the chord name based on the chord type
  let chordSuffix = '';
  
  switch (chordType) {
    case 'triad':
      if (isDiminished) {
        // Diminished chord
        chordSuffix = 'dim';
      } else if (isMajor) {
        // Major chord
        chordSuffix = '';
      } else {
        // Minor chord
        chordSuffix = 'm';
      }
      break;
    case '7th':
      if (isDiminished) {
        // Half-diminished 7th (m7b5) for vii in major scales
        // This is the correct name for B-D-F-A in C major
        chordSuffix = 'm7b5';
      } else if (isMajor) {
        // Major 7th or Dominant 7th
        if (degree === 5 || (!isMajorScale && (degree === 3 || degree === 7))) {
          chordSuffix = '7'; // Dominant 7th
        } else {
          chordSuffix = 'maj7'; // Major 7th
        }
      } else {
        // Minor 7th
        chordSuffix = 'm7';
      }
      break;
    case '9th':
      if (isDiminished) {
        // Half-diminished 9th
        chordSuffix = 'm9b5';
      } else if (isMajor) {
        // Major 9th or Dominant 9th
        if (degree === 5 || (!isMajorScale && (degree === 3 || degree === 7))) {
          chordSuffix = '9'; // Dominant 9th
        } else {
          chordSuffix = 'maj9'; // Major 9th
        }
      } else {
        // Minor 9th
        chordSuffix = 'm9';
      }
      break;
    case '13th':
      if (isDiminished) {
        // Half-diminished 13th
        chordSuffix = 'm13b5';
      } else if (isMajor) {
        // Major 13th or Dominant 13th
        if (degree === 5 || (!isMajorScale && (degree === 3 || degree === 7))) {
          chordSuffix = '13'; // Dominant 13th
        } else {
          chordSuffix = 'maj13'; // Major 13th
        }
      } else {
        // Minor 13th
        chordSuffix = 'm13';
      }
      break;
    case 'sus2':
      chordSuffix = 'sus2';
      break;
    case 'sus4':
      chordSuffix = 'sus4';
      break;
    case 'dim':
      chordSuffix = 'dim';
      break;
    case 'dom7':
      chordSuffix = '7'; // Dominant 7th
      break;
    default:
      chordSuffix = chordType;
  }
  
  // Return the formatted chord name with proper display name
  const rawChordName = `${rootNote}${chordSuffix}`;
  const displayChordName = getDisplayChordName(rawChordName);
  return displayChordName;
};

// Get the transition type for a genre
export const getTransitionType = (genre: string): string => {
  return GENRE_TRANSITION_TYPES[genre] || 'sus4'; // Default to sus4 if genre not found
};

// Fix the applyVoicing function to properly handle different voicings
function applyVoicing(chordNotes: string[], voicing: string, octave: number, chordType: string = 'triad'): string[] {
  // Special handling for the dynamic voicing
  if (voicing === 'dynamic') {
    return dynamicVoicing(chordNotes, chordType, octave);
  }
  
  // Get the voicing pattern for this voicing and chord type
  const voicingPattern = VOICING_PATTERNS[voicing]?.[chordType] || VOICING_PATTERNS['closed'][chordType];
  
  // Get the note order for this voicing and chord type
  const noteOrder = NOTE_ORDERS[voicing]?.[chordType] || NOTE_ORDERS['closed'][chordType];
  
  // Make sure we have a valid pattern and order
  if (!voicingPattern || !noteOrder) {
    return chordNotes;
  }
  
  // Make sure the pattern and order match the number of notes
  if (voicingPattern[0].length < chordNotes.length || noteOrder.length < chordNotes.length) {
    // Extend the pattern and order if needed
    while (voicingPattern[0].length < chordNotes.length) {
      voicingPattern[0].push(0);
    }
    while (noteOrder.length < chordNotes.length) {
      noteOrder.push(noteOrder.length);
    }
  }
  
  // Apply the voicing pattern and note order
  const result: string[] = [];
  
  // First, apply octave shifts based on the voicing pattern
  const shiftedNotes = chordNotes.map((note, index) => {
    // Get the octave shift for this note
    const octaveShift = voicingPattern[0][index] || 0;
    
    // Split the note into note name and octave
    const [noteName, noteOctave] = splitNoteAndOctave(note);
    
    // Calculate the new octave
    const newOctave = parseInt(noteOctave) + octaveShift;
    
    // Return the note with the new octave
    return `${noteName}${newOctave}`;
  });
  
  // Then, reorder the notes based on the note order
  for (let i = 0; i < noteOrder.length && i < chordNotes.length; i++) {
    const noteIndex = noteOrder[i];
    if (noteIndex < shiftedNotes.length) {
      result.push(shiftedNotes[noteIndex]);
    }
  }
  
  return result;
}

// Function to handle dynamic voicing according to the special rules
function dynamicVoicing(chordNotes: string[], chordType: string, octave: number): string[] {
  // If there are no notes, return an empty array
  if (!chordNotes || chordNotes.length === 0) {
    return [];
  }
  
  // Define the chromatic scale for comparing note positions
  const chromaticScale = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  
  // Helper to normalize note names (handle enharmonic equivalents)
  const normalizeNote = (note: string): string => {
    if (!note) return 'C'; // Default to C if no note provided
    if (note === 'Db') return 'C#';
    if (note === 'Eb') return 'D#';
    if (note === 'Fb') return 'E';
    if (note === 'Gb') return 'F#';
    if (note === 'Ab') return 'G#';
    if (note === 'Bb') return 'A#';
    if (note === 'Cb') return 'B';
    if (note === 'E#') return 'F';
    if (note === 'B#') return 'C';
    return note;
  };
  
  // Helper to get the chromatic position of a note
  const getNotePosition = (noteName: string): number => {
    if (!noteName) return 0; // Default to position 0 if no note name
    return chromaticScale.indexOf(normalizeNote(noteName));
  };
  
  // Extract note names and octaves
  const notesInfo = chordNotes.map(note => {
    if (!note) {
      // Return a default note object if the note is undefined
      return {
        name: 'C',
        octave: octave,
        position: 0
      };
    }
    
    const [noteName, noteOctave] = splitNoteAndOctave(note);
    return {
      name: noteName || 'C',
      octave: parseInt(noteOctave || octave.toString()),
      position: getNotePosition(noteName)
    };
  });
  
  // Get the root note info or use a default if undefined
  const rootInfo = notesInfo[0] || { name: 'C', octave: octave, position: 0 };
  
  let adjustedNotes: string[] = [];
  
  // Fall back to regular closed voicing if we don't have enough notes or if any note is invalid
  if (notesInfo.length < 3 || notesInfo.some(info => info.position === -1)) {
    // Apply the voicing pattern for closed voicing
    return applyVoicing(chordNotes, 'closed', octave, chordType);
  }
  
  if (chordType === 'triad') {
    // Handle triads
    // Make sure we have the 3rd, otherwise fallback to a safe value
    const thirdInfo = notesInfo[1] || { name: 'E', octave: rootInfo.octave, position: 4 };
    let thirdOctave = thirdInfo.octave;
    if (thirdInfo.position < rootInfo.position) {
      thirdOctave = rootInfo.octave;
    }
    
    // Make sure we have the 5th, otherwise fallback to a safe value
    const fifthInfo = notesInfo[2] || { name: 'G', octave: rootInfo.octave, position: 7 };
    let fifthOctave = fifthInfo.octave;
    if (fifthInfo.position < rootInfo.position) {
      fifthOctave = rootInfo.octave;
    }
    
    // Create adjusted notes
    adjustedNotes = [
      `${rootInfo.name}${rootInfo.octave}`,
      `${thirdInfo.name}${thirdOctave}`,
      `${fifthInfo.name}${fifthOctave}`
    ];
  } else if (chordType === '7th' && notesInfo.length >= 4) {
    // Handle 7ths
    // Apply the same rules as for triads for the triad part
    const thirdInfo = notesInfo[1] || { name: 'E', octave: rootInfo.octave, position: 4 };
    let thirdOctave = thirdInfo.octave;
    if (thirdInfo.position < rootInfo.position) {
      thirdOctave = rootInfo.octave;
    }
    
    const fifthInfo = notesInfo[2] || { name: 'G', octave: rootInfo.octave, position: 7 };
    let fifthOctave = fifthInfo.octave;
    if (fifthInfo.position < rootInfo.position) {
      fifthOctave = rootInfo.octave;
    }
    
    // For 7th: If 7th > root, stay in same octave, otherwise one octave up
    const seventhInfo = notesInfo[3] || { name: 'B', octave: rootInfo.octave, position: 11 };
    let seventhOctave = seventhInfo.octave;
    if (seventhInfo.position < rootInfo.position) {
      seventhOctave = rootInfo.octave + 1;
    }
    
    // Create adjusted notes
    adjustedNotes = [
      `${rootInfo.name}${rootInfo.octave}`,
      `${thirdInfo.name}${thirdOctave}`,
      `${fifthInfo.name}${fifthOctave}`,
      `${seventhInfo.name}${seventhOctave}`
    ];
  } else if (chordType === '9th' && notesInfo.length >= 5) {
    // Handle 9ths
    // Apply the same rules as for 7ths for the first four notes
    const thirdInfo = notesInfo[1] || { name: 'E', octave: rootInfo.octave, position: 4 };
    let thirdOctave = thirdInfo.octave;
    if (thirdInfo.position < rootInfo.position) {
      thirdOctave = rootInfo.octave;
    }
    
    const fifthInfo = notesInfo[2] || { name: 'G', octave: rootInfo.octave, position: 7 };
    let fifthOctave = fifthInfo.octave;
    if (fifthInfo.position < rootInfo.position) {
      fifthOctave = rootInfo.octave;
    }
    
    const seventhInfo = notesInfo[3] || { name: 'B', octave: rootInfo.octave, position: 11 };
    let seventhOctave = seventhInfo.octave;
    if (seventhInfo.position < rootInfo.position) {
      seventhOctave = rootInfo.octave + 1;
    }
    
    // For 9th: Move one octave higher than the root
    const ninthInfo = notesInfo[4] || { name: 'D', octave: rootInfo.octave, position: 2 };
    const ninthOctave = rootInfo.octave + 1;
    
    // Create adjusted notes
    adjustedNotes = [
      `${rootInfo.name}${rootInfo.octave}`,
      `${thirdInfo.name}${thirdOctave}`,
      `${fifthInfo.name}${fifthOctave}`,
      `${seventhInfo.name}${seventhOctave}`,
      `${ninthInfo.name}${ninthOctave}`
    ];
  } else if (chordType === '13th' && notesInfo.length >= 7) {
    // Handle 13ths
    // Apply the same rules as for 9ths for the first five notes
    const thirdInfo = notesInfo[1] || { name: 'E', octave: rootInfo.octave, position: 4 };
    let thirdOctave = thirdInfo.octave;
    if (thirdInfo.position < rootInfo.position) {
      thirdOctave = rootInfo.octave;
    }
    
    const fifthInfo = notesInfo[2] || { name: 'G', octave: rootInfo.octave, position: 7 };
    let fifthOctave = fifthInfo.octave;
    if (fifthInfo.position < rootInfo.position) {
      fifthOctave = rootInfo.octave;
    }
    
    const seventhInfo = notesInfo[3] || { name: 'B', octave: rootInfo.octave, position: 11 };
    let seventhOctave = seventhInfo.octave;
    if (seventhInfo.position < rootInfo.position) {
      seventhOctave = rootInfo.octave + 1;
    }
    
    const ninthInfo = notesInfo[4] || { name: 'D', octave: rootInfo.octave, position: 2 };
    const ninthOctave = rootInfo.octave + 1;
    
    // For 11th: Move one octave higher than the root
    const eleventhInfo = notesInfo[5] || { name: 'F', octave: rootInfo.octave, position: 5 };
    const eleventhOctave = rootInfo.octave + 1;
    
    // For 13th: If 13th > 11th, keep it within 11th octave, otherwise move one higher
    const thirteenthInfo = notesInfo[6] || { name: 'A', octave: rootInfo.octave, position: 9 };
    let thirteenthOctave = eleventhOctave;
    if (thirteenthInfo.position < eleventhInfo.position) {
      thirteenthOctave = eleventhOctave + 1;
    }
    
    // Create adjusted notes
    adjustedNotes = [
      `${rootInfo.name}${rootInfo.octave}`,
      `${thirdInfo.name}${thirdOctave}`,
      `${fifthInfo.name}${fifthOctave}`,
      `${seventhInfo.name}${seventhOctave}`,
      `${ninthInfo.name}${ninthOctave}`,
      `${eleventhInfo.name}${eleventhOctave}`,
      `${thirteenthInfo.name}${thirteenthOctave}`
    ];
  } else {
    // For other chord types or if not enough notes, just return the original notes
    return applyVoicing(chordNotes, 'closed', octave, chordType);
  }
  
  // Sort the notes by octave and then by position in the chromatic scale
  // to ensure the lower note is always first
  adjustedNotes.sort((a, b) => {
    if (!a || !b) return 0;
    
    const [aName, aOctave] = splitNoteAndOctave(a);
    const [bName, bOctave] = splitNoteAndOctave(b);
    
    if (!aName || !bName || !aOctave || !bOctave) return 0;
    
    const aOctaveNum = parseInt(aOctave);
    const bOctaveNum = parseInt(bOctave);
    
    if (aOctaveNum !== bOctaveNum) {
      return aOctaveNum - bOctaveNum;
    }
    
    return getNotePosition(aName) - getNotePosition(bName);
  });
  
  return adjustedNotes;
}

// Add or update the getRecommendedProgressions function
export const getRecommendedProgressions = (genre: string, currentDegree: number): number[] => {
  // Define common chord progressions by genre
  const progressions: Record<string, Record<number, number[]>> = {
    'pop': {
      1: [4, 5, 6],
      2: [5],
      3: [4, 6],
      4: [1, 5],
      5: [1, 6],
      6: [2, 4, 5],
      7: [1]
    },
    'rock': {
      1: [4, 5],
      2: [5],
      3: [6],
      4: [1, 5],
      5: [1, 6],
      6: [2, 4],
      7: [1]
    },
    '80sdisco': {
      1: [2, 4, 6],
      2: [5, 7],
      3: [6],
      4: [2, 5],
      5: [1, 3, 6],
      6: [2, 4, 5],
      7: [1, 6]
    },
    'hiphop': {
      1: [4, 5, 6],
      2: [5, 1],
      3: [2, 6],
      4: [5, 1],
      5: [6, 1],
      6: [5, 4, 2],
      7: [1, 6]
    },
    'trap': {
      1: [6, 5, 4],
      2: [1, 5],
      3: [6, 4],
      4: [5, 1],
      5: [6, 1],
      6: [5, 4, 1],
      7: [1, 6]
    },
    'rnb': {
      1: [4, 6, 2],
      2: [5, 3],
      3: [6, 2],
      4: [5, 2],
      5: [1, 3, 6],
      6: [2, 5, 4],
      7: [1, 3]
    },
    'funk': {
      1: [4, 3],
      2: [5, 1],
      3: [2, 6],
      4: [1, 5],
      5: [1, 6],
      6: [2, 5, 7],
      7: [3, 6]
    },
    'jazz': {
      1: [4, 6, 2],
      2: [5, 7],
      3: [6, 2],
      4: [7, 3],
      5: [1, 3],
      6: [2, 5],
      7: [3, 6]
    },
    'edm': {
      1: [5, 6],
      2: [5, 1],
      3: [4, 6],
      4: [1, 5],
      5: [6, 1],
      6: [4, 5],
      7: [1, 6]
    },
    'citypop': {
      1: [6, 3, 4],
      2: [5, 1],
      3: [6, 4],
      4: [5, 2],
      5: [1, 6, 3],
      6: [2, 4, 5],
      7: [1, 3]
    }
  };
  
  // Default to pop if genre not found
  const genreProgressions = progressions[genre] || progressions['pop'];
  
  // Return recommended next chords for the current degree
  return genreProgressions[currentDegree] || [];
};

// Completely rewritten getRootNoteWithOctave function with relative octave shifts
export const getRootNoteWithOctave = (scale: string, degree: number, baseOctave: number, isDynamicVoicing: boolean = false): string => {
  // Get the scale notes
  const scaleNotes = SCALES[scale] || SCALES['C Major'];
  
  // Get the root note name for this degree
  const rootNoteName = scaleNotes[(degree - 1) % scaleNotes.length];
  
  // Get the octave shift for this degree in this scale
  let octaveShift = 0;
  
  // Only apply SCALE_DEGREE_OCTAVE_SHIFTS if NOT using dynamic voicing
  if (!isDynamicVoicing && SCALE_DEGREE_OCTAVE_SHIFTS[scale] && degree <= SCALE_DEGREE_OCTAVE_SHIFTS[scale].length) {
    octaveShift = SCALE_DEGREE_OCTAVE_SHIFTS[scale][degree - 1];
  }
  
  // Apply the octave shift to the base octave
  const adjustedOctave = baseOctave + octaveShift;
  
  return `${rootNoteName}${adjustedOctave}`;
};

// Add a function to get the correct Roman numeral for a degree based on scale
export const getRomanNumeral = (degree: number, scale: string, isModalActive: boolean = false, isBorrowed: boolean = false): string => {
  // Extract if it's a major or minor scale
  // Handle both formats: "major"/"minor" and "C Major"/"C Minor"
  const isMajorScale = !scale.toLowerCase().includes('minor');
  
  // Define Roman numerals
  const romanNumerals = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii'];
  
  // Get the base Roman numeral
  const baseNumeral = romanNumerals[degree - 1];
  
  // If it's a borrowed chord, add the flat symbol
  if (isBorrowed) {
    // For borrowed chords, use lowercase when modal is active (to indicate minor)
    // and uppercase when modal is not active (to indicate major)
    return isModalActive 
      ? `♭${baseNumeral}` 
      : `♭${baseNumeral.toUpperCase()}`;
  }
  
  // Determine if this chord would normally be major in the current scale
  let isNormallyMajor = false;
  if (isMajorScale) {
    // In major scale: I, IV, V are major (degrees 1, 4, 5)
    isNormallyMajor = (degree === 1 || degree === 4 || degree === 5);
  } else {
    // In minor scale: III, VI, VII are major (degrees 3, 6, 7)
    isNormallyMajor = (degree === 3 || degree === 6 || degree === 7);
  }
  
  // If modal interchange is active, flip the chord quality
  const isMajor = isModalActive ? !isNormallyMajor : isNormallyMajor;
  
  // Determine if it's diminished
  let isDiminished = false;
  if (isMajorScale) {
    // In major scale: vii° is diminished
    isDiminished = (degree === 7);
  } else {
    // In minor scale: ii° is diminished
    isDiminished = (degree === 2);
  }
  
  // Format the Roman numeral
  let romanNumeral = baseNumeral;
  
  // Uppercase if major
  if (isMajor) {
    romanNumeral = romanNumeral.toUpperCase();
  }
  
  // Add diminished symbol if needed
  if (isDiminished) {
    romanNumeral += '°';
  }
  
  return romanNumeral;
};

const isDiminishedChord = (scale: string, degree: number): boolean => {
  // Check if it's a minor scale by looking for either 'm' or 'Minor'
  const isMinorScale = scale.includes('m') || scale.toLowerCase().includes('minor');
  
  // For major scales, vii is diminished
  if (!isMinorScale && degree === 7) return true;
  // For minor scales, ii is diminished
  if (isMinorScale && degree === 2) return true;
  return false;
};

// Define borrowed chord types with major intervals and semitone shift
const BORROWED_CHORD_TYPES: Record<string, Record<string, number[]>> = {
  'major': {
    'triad': [0, 4, 7], // Root (already lowered), major 3rd, perfect 5th
    '7th': [0, 4, 7, 11], // Root (already lowered), major 3rd, perfect 5th, minor 7th
    '9th': [0, 4, 7, 11, 14], // Root (already lowered), major 3rd, perfect 5th, minor 7th, 9th
    '13th': [0, 4, 7, 11, 14, 17, 21], // Root (already lowered), major 3rd, perfect 5th, minor 7th, 9th, 13th
    'sus4': [0, 5, 7], // Root (already lowered), 4th, 5th
    'sus2': [0, 2, 7], // Root (already lowered), 2nd, 5th
    'dom7': [0, 4, 7, 10] // Root (already lowered), major 3rd, perfect 5th, minor 7th
  },
  'minor': {
    'triad': [0, 3, 7], // Root (already lowered), minor 3rd, perfect 5th
    '7th': [0, 3, 7, 10], // Root (already lowered), minor 3rd, perfect 5th, minor 7th
    '9th': [0, 3, 7, 10, 14], // Root (already lowered), minor 3rd, perfect 5th, minor 7th, 9th
    '13th': [0, 3, 7, 10, 14, 17, 21], // Root (already lowered), minor 3rd, perfect 5th, minor 7th, 9th, 13th
    'sus4': [0, 5, 7], // Root (already lowered), 4th, 5th (sus chords don't change with modal)
    'sus2': [0, 2, 7], // Root (already lowered), 2nd, 5th (sus chords don't change with modal)
    'dom7': [0, 3, 7, 10] // Root (already lowered), minor 3rd, perfect 5th, minor 7th
  }
};

// Define relative octave shifts for borrowed chords
// Used to manually control octave placement for borrowed chords in different scales
// A value of 0 means no shift, 1 means shift up one octave, -1 means shift down one octave
const BORROWED_DEGREE_OCTAVE_SHIFTS: Record<string, number[]> = {
  // Major scales
  'C Major':  [-1, 0, 0, 0, 0, 0, 0],  // Changed from [-1, 0, 0, 0, 0, 0, 0]
  'G Major':  [0, 0, 0, 0, 1, 1, 1],
  'D Major':  [0, 0, 0, 0, 0, 0, 1],
  'A Major':  [0, 0, 1, 1, 1, 1, 1],
  'E Major':  [0, 0, 0, 0, 0, 1, 1],
  'B Major':  [0, 1, 1, 1, 1, 1, 1],
  'F# Major': [0, 0, 0, 0, 1, 1, 1],
  'Gb Major': [0, 0, 0, 0, 0, 0, 0],
  'C# Major': [0, 0, 0, 0, 0, 0, 0],
  'Ab Major': [0, 0, 0, 0, 1, 1, 1],
  'Eb Major': [0, 0, 0, 0, 0, 0, 1],
  'Bb Major': [0, 0, 0, 1, 1, 1, 1],
  'F Major':  [0, 0, 0, 0, 0, 1, 1],
  
  // Minor scales
  'C Minor':  [-1, 0, 0, 0, 0, 0, 0],
  'G Minor':  [0, 0, 0, 0, 1, 1, 1],
  'D Minor':  [0, 0, 0, 0, 0, 0, 0],
  'A Minor':  [0, 0, 0, 1, 1, 1, 1],
  'E Minor':  [0, 0, 0, 0, 0, 0, 1],
  'B Minor':  [0, 1, 1, 1, 1, 1, 1],
  'F# Minor': [0, 0, 0, 0, 1, 1, 1],
  'C# Minor': [0, 0, 0, 0, 0, 0, 0],
  'Ab Minor': [0, 0, 0, 0, 1, 1, 1],
  'Eb Minor': [0, 0, 0, 0, 0, 0, 1],
  'Bb Minor': [0, 0, 0, 1, 1, 1, 1],
  'F Minor':  [0, 0, 0, 0, 0, 1, 1]
};

export const BORROWED_ROOT_NOTES_MINOR: { [key: string]: string } = {
  'C': 'B',
  'D': 'Db',
  'Eb': 'D',
  'F': 'E',
  'G': 'Gb',
  'Ab': 'G',
  'Bb': 'A'
};

// Define mapping for all root notes to their lowered semitone equivalents
const BORROWED_ROOT_NOTES: { [key: string]: string } = {
  'C': 'B',
  'C#': 'C',
  'Db': 'C',
  'D': 'C#',
  'D#': 'D',
  'Eb': 'D',
  'E': 'Eb',
  'F': 'E',
  'F#': 'F',
  'Gb': 'F',
  'G': 'F#',
  'G#': 'G',
  'Ab': 'G',
  'A': 'Ab',  // Changed from 'G#' to 'Ab' to match standard notation
  'A#': 'A',
  'Bb': 'A',
  'B': 'Bb'
};

// Add this at the top of the file with other constants
const CHROMATIC_SCALE = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

// Define the type for chord types
type ChordType = 'triad' | '7th' | '9th' | '13th';

// Define borrowed root notes for each scale
const BORROWED_ROOT_NOTES_BY_SCALE: Record<string, string[]> = {
 // Major scales
 'C Major': ['B', 'C#', 'D#', 'E', 'F#', 'G#', 'A#'],
 'G Major': ['F#', 'G#', 'A#', 'B', 'C#', 'D#', 'F'],
 'D Major': ['C#', 'D#', 'F', 'F#', 'G#', 'A#', 'C'],
 'A Major': ['G#', 'A#', 'C', 'C#', 'D#', 'F', 'G'],
 'E Major': ['D#', 'F', 'G', 'G#', 'A#', 'C', 'D'],
 'B Major': ['A#', 'C', 'D', 'D#', 'F', 'G', 'A'],
 'F# Major': ['F', 'G', 'A', 'A#', 'C', 'D', 'E'],
 'Gb Major': ['F', 'G', 'A', 'A#', 'C', 'D', 'E'],
 'C# Major': ['C', 'D', 'E', 'F', 'G', 'A', 'B'],
 'Ab Major': ['G', 'G#', 'A#', 'B', 'C#', 'D#', 'F'],
 'Eb Major': ['D', 'D#', 'F', 'F#', 'G#', 'A#', 'C'],
 'Bb Major': ['G#', 'A#', 'B', 'C#', 'D#', 'F', 'G'],
 'F Major': ['E', 'F#', 'G#', 'A', 'B', 'C#', 'D#'],

// Minor scales
 'C Minor': ['B', 'C#', 'D', 'E', 'F#', 'G', 'A'],
 'G Minor': ['F#', 'G#', 'A', 'B', 'C#', 'D', 'E'],
 'D Minor': ['C#', 'D#', 'E', 'F#', 'G#', 'A', 'B'],
 'A Minor': ['G#', 'A#', 'B', 'C#', 'D#', 'E', 'F#'],
 'E Minor': ['D#', 'F', 'F#', 'G#', 'A#', 'B', 'C#'],
 'B Minor': ['A#', 'C', 'C#', 'D#', 'F', 'F#', 'G#'],
 'F# Minor': ['F', 'G', 'G#', 'A#', 'C', 'C#', 'D#'],
 'C# Minor': ['C', 'D', 'D#', 'F', 'G', 'G#', 'A#'],
 'Ab Minor': ['G', 'G#', 'A#', 'B', 'C#', 'D#', 'F'],
 'Eb Minor': ['C#', 'D#', 'F', 'F#', 'G#', 'A#', 'C'],
 'Bb Minor': ['G#', 'A#', 'B', 'C#', 'D#', 'F', 'G'],
 'F Minor': ['E', 'F#', 'G', 'G#', 'A#', 'C', 'D']
};

export const getBorrowedNotes = ({
  scale,
  degree,
  octave,
  chordType,
  voicing,
  isModalActive
}: {
  scale: string;
  degree: number;
  octave: number;
  chordType: string;
  voicing: string;
  isModalActive: boolean;
}): string[] => {
  // Get the lowered root note directly from our scale-specific mapping
  const loweredRoot = BORROWED_ROOT_NOTES_BY_SCALE[scale]?.[degree - 1];
  if (!loweredRoot) {
    console.error(`No borrowed root note found for scale ${scale} and degree ${degree}`);
    return [];
  }

  // Get the octave shift for this degree in this scale, but only if not using dynamic voicing
  let octaveShift = 0;
  if (voicing !== 'dynamic' && BORROWED_DEGREE_OCTAVE_SHIFTS[scale] && degree <= BORROWED_DEGREE_OCTAVE_SHIFTS[scale].length) {
    octaveShift = BORROWED_DEGREE_OCTAVE_SHIFTS[scale][degree - 1];
  }
  const adjustedOctave = octave + octaveShift;

  // Get the chord intervals from the borrowed chord types
  // Use minor chords when modal is active, otherwise use major chords
  const chordSet = isModalActive ? 'minor' : 'major';
  const intervals = BORROWED_CHORD_TYPES[chordSet][chordType] || BORROWED_CHORD_TYPES[chordSet].triad;

  // Normalize notes to handle flats and other enharmonic equivalents
  const normalizeNote = (note: string): string => {
    if (note === 'Cb') return 'B';
    if (note === 'Fb') return 'E';
    if (note === 'E#') return 'F';
    if (note === 'B#') return 'C';
    if (note === 'Db') return 'C#';
    if (note === 'Eb') return 'D#';
    if (note === 'Gb') return 'F#';
    if (note === 'Ab') return 'G#';
    if (note === 'Bb') return 'A#';
    return note;
  };
  
  // Get root position in chromatic scale
  const normalizedRoot = normalizeNote(loweredRoot);
  const rootPosition = CHROMATIC_SCALE.indexOf(normalizedRoot);
  
  // Calculate the notes and apply correct octaves
  const chordNotes = [];
  
  // Keep track of the current position in the chromatic scale and the current octave
  let currentPosition = rootPosition;
  let currentOctave = adjustedOctave;
  
  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const note = calculateNoteFromInterval(loweredRoot, interval);
    
    // Get the normalized note for position comparison
    const normalizedNote = normalizeNote(note);
    const notePosition = CHROMATIC_SCALE.indexOf(normalizedNote);
    
    // If this is not the first note, compare with the previous note's position
    if (i > 0) {
      const previousNote = chordNotes[i - 1];
      const previousNormalizedNote = normalizeNote(previousNote.replace(/\d+$/, ''));
      const previousPosition = CHROMATIC_SCALE.indexOf(previousNormalizedNote);
      
      // If the new note is lower in the chromatic scale than the previous note,
      // move up an octave
      if (notePosition < previousPosition) {
        currentOctave += 1;
      }
    }
    
    // Update the current position for the next iteration
    currentPosition = notePosition;
    
    // Use the display name for the note
    const displayNote = getDisplayNoteName(note);
    const finalNote = `${displayNote}${currentOctave}`;
    chordNotes.push(finalNote);
  }
  
  // Apply voicing if needed
  if (voicing !== 'closed') {
    return applyVoicing(chordNotes, voicing, octave, chordType);
  }
  
  return chordNotes;
};

// Helper function to get semitones for a note
function getNoteSemitones(note: string): number {
  const noteOrder = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  return noteOrder.indexOf(note);
}