import React, { useEffect, useState } from 'react';
import './BorrowedRow.css';
import { getChordName, getRecommendedProgressions, getScaleNotes, getRootNoteWithOctave, getRomanNumeral, getBorrowedNotes } from '../utils/musicTheory';

interface BorrowedRowProps {
  scale: string;
  pressedKeys: Set<string>;
  playChord?: (scale: string, degree: number, octave: number, chordType: string, voicing: string, isModalActive: boolean, isKeyDown?: boolean, isBorrowed?: boolean, predefinedNotes?: string[]) => void;
  octave: number;
  activeChordType?: string;
  activeVoicing?: string;
  isModalActive: boolean;
  genre?: string;
  chordType?: string;
  voicing?: string;
  onPlayChord?: (degree: number, isKeyDown: boolean) => void;
  keyValue?: string;
}

const BorrowedRow: React.FC<BorrowedRowProps> = ({
  scale,
  pressedKeys,
  playChord,
  octave,
  activeChordType,
  activeVoicing,
  isModalActive,
  genre = 'pop',
  chordType,
  voicing,
  onPlayChord,
  keyValue
}) => {
  const [activeChords, setActiveChords] = useState<Set<number>>(new Set());
  const [recommendedChords, setRecommendedChords] = useState<number[]>([]);
  
  const effectiveChordType = chordType || activeChordType || 'triad';
  const effectiveVoicing = voicing || activeVoicing || 'closed';
  
  const chordDegreeKeys = ['R', 'T', 'Y', 'U', 'I', 'O', 'P'];
  
  // Update active chords when pressed keys change
  useEffect(() => {
    const newActiveChords = new Set<number>();
    
    chordDegreeKeys.forEach((key, index) => {
      if (pressedKeys.has(key.toLowerCase())) {
        newActiveChords.add(index + 1);
      }
    });
    
    setActiveChords(newActiveChords);
  }, [pressedKeys]);
  
  // Update recommended chords when active chords change
  useEffect(() => {
    // If there's an active chord, get recommendations
    if (activeChords.size > 0) {
      // Get the first active chord (in case multiple are pressed)
      const activeDegree = Array.from(activeChords)[0];
      
      // Get recommended progressions based on genre and current chord
      const recommendations = getRecommendedProgressions(genre, activeDegree);
      
      setRecommendedChords(recommendations);
    } else {
      // Clear recommendations if no chord is active
      setRecommendedChords([]);
    }
  }, [activeChords, genre]);
  
  const handleChordMouseDown = (degree: number) => {
    if (onPlayChord) {
      onPlayChord(degree, true);
    } else if (playChord) {
      // Get the current key and scale
      const currentKeyAndScale = `${keyValue} ${scale === 'major' ? 'Major' : 'Minor'}`;
      
      // Get the borrowed notes for this chord
      const notes = getBorrowedNotes({
        scale: currentKeyAndScale,  // Pass the full scale name including key
        degree,
        octave,
        chordType: effectiveChordType,
        voicing: effectiveVoicing,
        isModalActive
      });
      
      // Play the chord with the borrowed notes
      playChord(scale, degree, octave, effectiveChordType, effectiveVoicing, isModalActive, true, true, notes);
    }
    
    setActiveChords(prev => {
      const newSet = new Set(prev);
      newSet.add(degree);
      return newSet;
    });
  };
  
  const handleChordMouseUp = (degree: number) => {
    if (onPlayChord) {
      onPlayChord(degree, false);
    } else if (playChord) {
      playChord(scale, degree, octave, effectiveChordType, effectiveVoicing, isModalActive, false, true);
    }
    
    setActiveChords(prev => {
      const newSet = new Set(prev);
      newSet.delete(degree);
      return newSet;
    });
  };
  
  const handleChordMouseLeave = (degree: number) => {
    if (activeChords.has(degree)) {
      if (onPlayChord) {
        onPlayChord(degree, false);
      } else if (playChord) {
        playChord(scale, degree, octave, effectiveChordType, effectiveVoicing, isModalActive, false, true);
      }
      
      setActiveChords(prev => {
        const newSet = new Set(prev);
        newSet.delete(degree);
        return newSet;
      });
    }
  };
  
  // Get the current key and scale
  const currentKeyAndScale = `${keyValue} ${scale === 'major' ? 'Major' : 'Minor'}`;
  
  // Get the scale notes
  const scaleNotes = getScaleNotes(currentKeyAndScale);
  
  // Create chord buttons
  const chordButtons = [];
  for (let i = 1; i <= 7; i++) {
    const isActive = activeChords.has(i);
    const isRecommended = recommendedChords.includes(i);
    const keyIndicator = chordDegreeKeys[i - 1];
    
    // Get the chord name
    const chordName = getChordName(currentKeyAndScale, i, effectiveChordType, isModalActive, true);
    
    // Extract the root note and chord type suffix
    const rootNoteMatch = chordName.match(/^([A-G][#b]?)/);
    const rootNote = rootNoteMatch ? rootNoteMatch[1] : '';
    
    // Extract the chord type suffix (everything after the root note)
    const chordTypeSuffix = chordName.substring(rootNote.length);
    
    // Get the Roman numeral for this degree
    const romanNumeral = getRomanNumeral(i, currentKeyAndScale, isModalActive, true);
    
    // Get the root note with the correct octave
    const rootNoteWithOctave = getRootNoteWithOctave(currentKeyAndScale, i, octave);
    
    chordButtons.push(
      <div
        key={i}
        className={`borrowed-chord-button ${isActive ? 'active' : ''}`}
        onMouseDown={() => handleChordMouseDown(i)}
        onMouseUp={() => handleChordMouseUp(i)}
        onMouseLeave={() => handleChordMouseLeave(i)}
        onTouchStart={() => handleChordMouseDown(i)}
        onTouchEnd={() => handleChordMouseUp(i)}
      >
        <div className="numeral">
          {romanNumeral}
        </div>
        <div className="key-letter">{keyIndicator}</div>
      </div>
    );
  }
  
  return (
    <div className="borrowed-row">
      <div className="chord-buttons">
        {chordButtons}
      </div>
    </div>
  );
};

export default BorrowedRow; 