import React, { useEffect, useState } from 'react';
import './ProgressionRow.css';
import { getChordName, getRecommendedProgressions, getScaleNotes, getRootNoteWithOctave, getRomanNumeral } from '../utils/musicTheory';

interface ProgressionRowProps {
  scale: string;
  pressedKeys: Set<string>;
  playChord?: (scale: string, degree: number, octave: number, chordType: string, voicing: string, isModalActive: boolean, isKeyDown?: boolean) => void;
  octave: number;
  activeChordType?: string;
  activeVoicing?: string;
  isModalActive: boolean;
  genre?: string;
  chordType?: string;
  voicing?: string;
  onPlayChord?: (degree: number, isKeyDown: boolean) => void;
  keyValue?: string;
}

const ProgressionRow: React.FC<ProgressionRowProps> = ({
  scale,
  pressedKeys,
  playChord,
  octave,
  activeChordType,
  activeVoicing,
  isModalActive,
  genre = 'pop',
  chordType,
  voicing,
  onPlayChord,
  keyValue
}) => {
  const [activeChords, setActiveChords] = useState<Set<number>>(new Set());
  const [recommendedChords, setRecommendedChords] = useState<number[]>([]);
  
  const effectiveChordType = chordType || activeChordType || 'triad';
  const effectiveVoicing = voicing || activeVoicing || 'closed';
  
  const chordDegreeKeys = ['f', 'g', 'h', 'j', 'k', 'l', ';'];
  
  // Update active chords when pressed keys change
  useEffect(() => {
    const newActiveChords = new Set<number>();
    
    chordDegreeKeys.forEach((key, index) => {
      if (pressedKeys.has(key)) {
        newActiveChords.add(index + 1);
      }
    });
    
    setActiveChords(newActiveChords);
  }, [pressedKeys]);
  
  // Update recommended chords when active chords change
  useEffect(() => {
    // If there's an active chord, get recommendations
    if (activeChords.size > 0) {
      // Get the first active chord (in case multiple are pressed)
      const activeDegree = Array.from(activeChords)[0];
      
      // Get recommended progressions based on genre and current chord
      const recommendations = getRecommendedProgressions(genre, activeDegree);
      
      setRecommendedChords(recommendations);
    } else {
      // Clear recommendations if no chord is active
      setRecommendedChords([]);
    }
  }, [activeChords, genre]);
  
  const handleChordMouseDown = (degree: number) => {
    if (onPlayChord) {
      onPlayChord(degree, true);
    } else if (playChord) {
      playChord(scale, degree, octave, effectiveChordType, effectiveVoicing, isModalActive, true);
    }
    
    setActiveChords(prev => {
      const newSet = new Set(prev);
      newSet.add(degree);
      return newSet;
    });
  };
  
  const handleChordMouseUp = (degree: number) => {
    if (onPlayChord) {
      onPlayChord(degree, false);
    } else if (playChord) {
      playChord(scale, degree, octave, effectiveChordType, effectiveVoicing, isModalActive, false);
    }
    
    setActiveChords(prev => {
      const newSet = new Set(prev);
      newSet.delete(degree);
      return newSet;
    });
  };
  
  const handleChordMouseLeave = (degree: number) => {
    if (activeChords.has(degree)) {
      if (onPlayChord) {
        onPlayChord(degree, false);
      } else if (playChord) {
        playChord(scale, degree, octave, effectiveChordType, effectiveVoicing, isModalActive, false);
      }
      
      setActiveChords(prev => {
        const newSet = new Set(prev);
        newSet.delete(degree);
        return newSet;
      });
    }
  };
  
  // Get the current key and scale
  const currentKeyAndScale = `${keyValue} ${scale === 'major' ? 'Major' : 'Minor'}`;
  
  // Get the scale notes
  const scaleNotes = getScaleNotes(currentKeyAndScale);
  
  // Create chord buttons
  const chordButtons = [];
  for (let i = 1; i <= 7; i++) {
    const isActive = activeChords.has(i);
    const isRecommended = recommendedChords.includes(i);
    const keyIndicator = chordDegreeKeys[i - 1].toUpperCase();
    
    // Get the chord name
    const chordName = getChordName(currentKeyAndScale, i, effectiveChordType, isModalActive);
    
    // Get the Roman numeral using the imported function
    const romanNumeral = getRomanNumeral(i, currentKeyAndScale, isModalActive);
    
    // Get the root note with the correct octave
    const rootNote = getRootNoteWithOctave(currentKeyAndScale, i, octave);
    
    chordButtons.push(
      <div
        key={i}
        className={`chord-button ${isActive ? 'active' : ''} ${isRecommended ? 'highlighted' : ''}`}
        onMouseDown={() => handleChordMouseDown(i)}
        onMouseUp={() => handleChordMouseUp(i)}
        onMouseLeave={() => handleChordMouseLeave(i)}
        onTouchStart={() => handleChordMouseDown(i)}
        onTouchEnd={() => handleChordMouseUp(i)}
      >
        <div className="numeral">
          {romanNumeral}
        </div>
        <div className="key-letter">{keyIndicator}</div>
      </div>
    );
  }
  
  return (
    <div className="progression-row">
      <div className="chord-buttons">
        {chordButtons}
      </div>
      <div className="line-progression"></div>
    </div>
  );
};

export default ProgressionRow; 