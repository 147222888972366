import React, { useState, useRef, useEffect } from 'react';
import './KeyScaleSelector.css';

interface KeyScaleSelectorProps {
  keyValue: string;
  scaleValue: string;
  onKeyChange: (value: string) => void;
  onScaleChange: (value: string) => void;
  id?: string;
}

const KeyScaleSelector: React.FC<KeyScaleSelectorProps> = ({
  keyValue,
  scaleValue,
  onKeyChange,
  onScaleChange,
  id
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Define key options
  const keyOptions = [
    { value: 'C', label: 'C' },
    { value: 'G', label: 'G' },
    { value: 'D', label: 'D' },
    { value: 'A', label: 'A' },
    { value: 'E', label: 'E' },
    { value: 'B', label: 'B' },
    { value: 'F#', label: 'F#' },
    { value: 'C#', label: 'C#' },
    { value: 'F', label: 'F' },
    { value: 'Bb', label: 'Bb' },
    { value: 'Eb', label: 'Eb' },
    { value: 'Ab', label: 'Ab' },
  ];

  // Define scale options
  const scaleOptions = [
    { value: 'major', label: 'major' },
    { value: 'minor', label: 'minor' },
  ];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get the display value for the selector
  const displayValue = `${keyValue} ${scaleValue === 'major' ? 'major' : 'minor'}`;

  // Create enhanced handlers with logging
  const handleKeyChange = (value: string) => {
    console.log(`KeyScaleSelector: Key changing to ${value}`);
    onKeyChange(value);
  };

  const handleScaleChange = (value: string) => {
    console.log(`KeyScaleSelector: Scale changing to ${value}`);
    onScaleChange(value);
  };

  return (
    <div className="key-scale-dropdown-container" ref={dropdownRef}>
      <div 
        className={`dropdown-selector ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        id={id}
      >
        <div className='dropdown-label'>scale</div>
        <div className="selected-value">{displayValue}</div>
      </div>
      
      {isOpen && (
        <div className="key-scale-dropdown-options">
          <div className="key-scale-columns">
            <div className="key-column">
              <div className="key-options">
                {keyOptions.map((option) => (
                  <div
                    key={option.value}
                    className={`key-option ${option.value === keyValue ? 'selected' : ''}`}
                    onClick={() => {
                      handleKeyChange(option.value);
                      // Don't close the dropdown when selecting a key
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="scale-column">
              <div className="scale-options">
                {scaleOptions.map((option) => (
                  <div
                    key={option.value}
                    className={`scale-option ${option.value === scaleValue ? 'selected' : ''}`}
                    onClick={() => {
                      handleScaleChange(option.value);
                      // Don't close the dropdown when selecting a scale
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="done-button" onClick={() => setIsOpen(false)}>
            close
          </div>
        </div>
      )}
    </div>
  );
};

export default KeyScaleSelector; 